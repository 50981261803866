import React, { useState } from 'react';
import PropTypes from 'prop-types';
import WithLoadingAndEmpty from '../../shared/WithLoadingAndEmpty';
import { Switch, Route } from 'react-router-dom';
import DynamicContentFullPageList from './DynamicContentFullPageList';
import WithInstantSearch from '../../shared/search/WithInstantSearch';
import DynamicContentMain from './DynamicContentMain';

function DynamicContentContent(props) {
  const [deletedItems, setDeletedItems] = useState([]);
  const renderContent = ({ match }) => {
    return (
      <DynamicContentMain
        dynamicContent={props.dynamicContent}
        fetchItems={props.fetchItems}
        isPaginating={props.isPaginating}
        isSearching={props.isSearching}
        match={match}
        pagination={props.pagination}
        searchAttributes={props.searchAttributes}
        searchState={props.searchState}
        onBackToList={props.onBackToList}
        onContentAdd={props.onContentAdd}
        deletedItems={deletedItems}
        setDeletedItems={setDeletedItems}
      />
    );
  };

  const renderEmptyContent = () => {
    return (
      <DynamicContentFullPageList
        dynamicContent={props.dynamicContent}
        fetchItems={props.fetchItems}
        searchState={props.searchState}
        searchAttributes={props.searchAttributes}
        pagination={props.pagination}
        isPaginating={props.isPaginating}
        isSearching={props.isSearching}
        deletedItems={deletedItems}
        setDeletedItems={setDeletedItems}
      />
    );
  };

  return (
    <Switch>
      <Route path={`${props.match.path}/:id`} render={renderContent} />
      <Route exact path={props.match.path} render={renderEmptyContent} />
    </Switch>
  );
}
DynamicContentContent.propTypes = {
  dynamicContent: PropTypes.array,
  fetchItems: PropTypes.func,
  isPaginating: PropTypes.bool,
  isSearching: PropTypes.bool,
  match: PropTypes.any,
  pagination: PropTypes.object,
  searchAttributes: PropTypes.object,
  searchState: PropTypes.object,
  onBackToList: PropTypes.func,
  onContentAdd: PropTypes.func,
};

export default WithLoadingAndEmpty(WithInstantSearch(DynamicContentContent));
