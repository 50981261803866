import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { flexRender } from '@tanstack/react-table';
import ReactTableDescriptionDropdown from 'components/shared/ReactTableDescriptionDropdown';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { UserContext } from '../UserContext';

export function Cell({ cell, cellClass, cellRefs, cellStyle, onCellClick }) {
  return (
    <td
      key={cell?.id}
      className={cellClass}
      ref={(element) => (cellRefs.current[cell.id] = element)}
      style={cellStyle}
      onClick={() => onCellClick?.(cell.id)}
    >
      {flexRender(cell?.column?.columnDef?.cell, cell?.getContext())}
    </td>
  );
}

export function CellDescription({
  cell,
  cellClass,
  cellDropdownId,
  cellRefs,
  cellStyle,
  onCellClick,
  onClose,
  rowData,
}) {
  const flags = useFlags();
  const userContext = useContext(UserContext);

  return (
    <>
      <Cell cell={cell} cellClass={cellClass} cellRefs={cellRefs} cellStyle={cellStyle} onCellClick={onCellClick} />
      {cellDropdownId === cell.id &&
        flags.queryExplainer &&
        !userContext.user?.enterprise?.settings?.disable_all_ai && (
          <ReactTableDescriptionDropdown cellRef={cellRefs?.current?.[cell.id]} rowData={rowData} onClose={onClose} />
        )}
    </>
  );
}

Cell.propTypes = {
  cell: PropTypes.object,
  cellClass: PropTypes.string,
  cellRefs: PropTypes.func,
  cellStyle: PropTypes.object,
  onCellClick: PropTypes.func,
};

CellDescription.propTypes = {
  cell: PropTypes.object,
  cellClass: PropTypes.string,
  cellDropdownId: PropTypes.string,
  cellRefs: PropTypes.func,
  cellStyle: PropTypes.object,
  onCellClick: PropTypes.func,
  onClose: PropTypes.func,
  rowData: PropTypes.object,
};
