import ApiReturnFields from './ApiReturnFields';
import PropTypes from 'prop-types';
import React from 'react';

function SimpleReturnFields({
  allFields,
  returnFields,
  returnFieldMapping,
  onReturnFieldAdd,
  onReturnFieldRemove,
  onReturnFieldSelectAll,
  onReturnFieldClear,
  onReturnFieldMappingUpdate,
  dataSourceName,
  removeToolTip,
  noOptionsMessage,
}) {
  let inErrorState = false;
  const dataSourceNameMsg = dataSourceName || 'Data Source';

  const allFieldsConcatReturn = [...allFields, ...returnFields];
  const allFieldsUnionReturn = [...new Set(allFieldsConcatReturn)];

  const fieldOptions = allFieldsUnionReturn.map((fieldName) => {
    const selected = returnFields.includes(fieldName);
    const fieldInErrorState = !allFields.includes(fieldName) && returnFields.includes(fieldName);

    if (fieldInErrorState) {
      inErrorState = true;
    }

    return {
      name: fieldName,
      selected: selected,
      inErrorState: fieldInErrorState,
      errorMessage: fieldInErrorState
        ? `This field can no longer be found in ${dataSourceNameMsg}. Remove it or replace it with a valid field`
        : '',
      hidden: false,
      hideable: false,
      hideToolTip: '',
      removable: true,
      removeToolTip: removeToolTip,
      iconName: null,
      iconToolTip: '',
      alias: returnFieldMapping[fieldName] || '',
    };
  });

  return (
    <ApiReturnFields
      returnFields={returnFields}
      inErrorState={inErrorState}
      errorMessage={inErrorState ? 'Contains fields that cannot be found in Data Source' : ''}
      fieldOptions={fieldOptions}
      canHide={false}
      onReturnFieldAdd={onReturnFieldAdd}
      onReturnFieldRemove={onReturnFieldRemove}
      onReturnFieldSelectAll={onReturnFieldSelectAll}
      onReturnFieldClear={onReturnFieldClear}
      onReturnFieldHide={null}
      onReturnFieldShow={null}
      onReturnFieldsMappingUpdate={onReturnFieldMappingUpdate}
      isReadOnly={false}
      noOptionsMessage={noOptionsMessage}
      canCreateReturnField={false}
    />
  );
}

SimpleReturnFields.propTypes = {
  allFields: PropTypes.array,
  returnFields: PropTypes.array,
  returnFieldMapping: PropTypes.object,
  onReturnFieldAdd: PropTypes.func,
  onReturnFieldRemove: PropTypes.func,
  onReturnFieldSelectAll: PropTypes.func,
  onReturnFieldClear: PropTypes.func,
  onReturnFieldMappingUpdate: PropTypes.func,
  dataSourceName: PropTypes.string,
  removeToolTip: PropTypes.string,
  noOptionsMessage: PropTypes.string,
};

export default SimpleReturnFields;
