import React from 'react';
import PropTypes from 'prop-types';
import InputsList from './InputsList';

const InputsSidebar = (props) => {
  return (
    <div className="sidebar">
      <InputsList
        inputs={props.inputs}
        selectedInput={props.currentInput}
        searchState={props.searchState}
        searchAttributes={props.searchAttributes}
        pagination={props.pagination}
        fetchItems={props.fetchItems}
        isPaginating={props.isPaginating}
        isSearching={props.isSearching}
        deletedItems={props.deletedItems}
      />
    </div>
  );
};

InputsSidebar.propTypes = {
  currentInput: PropTypes.object,
  fetchItems: PropTypes.func,
  isPaginating: PropTypes.bool,
  isSearching: PropTypes.bool,
  pagination: PropTypes.object,
  inputs: PropTypes.array,
  searchState: PropTypes.object,
  searchAttributes: PropTypes.object,
  deletedItems: PropTypes.array,
};

export default InputsSidebar;
