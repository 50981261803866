import { find } from 'lodash';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import API from '../api';
import Constants from '../../components/Constants';
import ConditionalContentProcessor from '../conditionalContentProcessor';
import inputs from '../inputs';
import InputsList from '../inputsList';
import utils from '../utils';

/**
 *  Returns input list based on dynamic content in a template or slide.
 * @param fullDynamicContentList All dynamic content referenced by the template or slide.
 * @param taggedDynamicContentNames Dynamic content specifically tagged: used to differentiate between conditional content
 * @param inputValues Input values entered so far: used to evaluate conditional content
 * @param inputOrder Ordering of the inputs, used to order the final output.
 * @param existingInputs Existing inputs passed in from the presentation detail page
 * @returns {{isLoading: *, orderedInputList: *[], isError: *, error: *, inputValues: {}}}
 */
export const useDynamicContentInputList = (
  fullDynamicContentList = [],
  taggedDynamicContentNames = [],
  inputValues = {},
  inputOrder,
  existingInputs,
) => {
  const parsedInputValues = { ...inputValues } || {};
  const dynamicContentIds = fullDynamicContentList
    ? fullDynamicContentList.map((dynamicContent) => dynamicContent.id)
    : [];
  // 1. Get all inputs associated with dynamic content list
  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['dynamic_content_input_list', dynamicContentIds],
    queryFn: () =>
      new Promise((resolve, reject) => {
        if (dynamicContentIds.length === 0) {
          return resolve({ inputsById: {}, inputIdsByDynamicContent: {} });
        }
        return API.post(
          '/dynamic_content/parameters/',
          { dynamic_content_ids: dynamicContentIds },
          (response) =>
            resolve({
              inputsById: response.data.params_by_id,
              inputIdsByDynamicContentId: response.data.param_ids_by_content_id,
              nestedContentById: response.data.nested_content_by_id || {},
            }),
          (err) => reject(err),
        );
      }),
  });

  const queryClient = useQueryClient();

  const invalidateAll = () => {
    queryClient.invalidateQueries({ queryKey: ['dynamic_content_input_list'] });
  };

  if (isLoading || isError) {
    return {
      isLoading,
      isError,
      orderedInputList: [],
      inputValues: {},
      error,
      invalidateAll,
    };
  }

  // 2. Update content list to take into account conditional content
  const getFinalContentList = (dynamicContentByName, taggedDynamicContentNames, inputValues) => {
    const finalContentList = new Set();
    for (const contentName of taggedDynamicContentNames) {
      const dynamicContent = dynamicContentByName[contentName];
      if (dynamicContent && dynamicContent.dynamic_content_type === Constants.DynamicContentTypes.CONDITIONAL) {
        const conditionalContentProcessor = new ConditionalContentProcessor(
          utils.safeJSONParse(dynamicContent.query_obj.query_string),
          inputValues,
        );
        const contentId = conditionalContentProcessor.getOutputContent();
        if (contentId) {
          const contentObj = find(dynamicContentByName, (content) => content.id === contentId);
          if (contentObj) {
            finalContentList.add(contentObj);
          }
        }
      }
      if (dynamicContent) {
        finalContentList.add(dynamicContent);
      }
    }

    const dynamicContentById = {};
    Object.values(dynamicContentByName || {}).forEach((dc) => (dynamicContentById[dc.id] = dc));

    for (const dynamicContent of finalContentList) {
      const nestedContentFilter = getNestedContentFilterFromQueryString(
        dynamicContent.query_obj?.query_string,
        dynamicContent.contentType,
        inputValues,
      );
      if (dynamicContent.contentType === Constants.DynamicContentTypes.CONDITIONAL) {
        const nestedContentIds = nestedContentFilter.id;
        if (nestedContentIds && nestedContentIds.length > 0) {
          nestedContentIds.forEach((nestedContentId) => {
            const dynamicContentToAdd = dynamicContentById[nestedContentId];
            if (dynamicContentToAdd) {
              finalContentList.add(dynamicContentToAdd);
            }
          });
        }
      } else {
        const nestedContentNames = nestedContentFilter.name || [];
        nestedContentNames.forEach((contentName) => {
          const dynamicContentToAdd = dynamicContentByName[contentName];
          if (dynamicContentToAdd) {
            finalContentList.add(dynamicContentToAdd);
          }
        });
      }
    }

    return finalContentList;
  };

  // 3. Get final input list based on final content list
  const getFinalInputList = (dynamicContentList, inputsById, inputsByDynamicContentId, nestedContentById) => {
    const getReferencedInputs = (contentId, checkedContent = new Set()) => {
      // cyclic reference check
      if (checkedContent.has(contentId)) {
        return [];
      }
      checkedContent.add(contentId);

      const inputIds = [];
      if (inputsByDynamicContentId[contentId]) {
        inputIds.push(...inputsByDynamicContentId[contentId]);
      }
      const nestedContent = nestedContentById[contentId];
      nestedContent?.forEach((nestedId) => {
        const nestedInputs = getReferencedInputs(nestedId, checkedContent);
        inputIds.push(...nestedInputs);
      });
      return [...new Set(inputIds)];
    };

    const finalInputs = new Set();
    dynamicContentList.forEach((dynamicContent) => {
      const inputIds = getReferencedInputs(dynamicContent.id);
      inputIds.forEach((inputId) => {
        if (inputsById?.[inputId]) {
          const input = inputsById[inputId];
          if (!parsedInputValues[input.name]) {
            parsedInputValues[input.name] = inputs.initInputValue(input);
          }
          finalInputs.add(input);
        }
      });
    });

    return [finalInputs, parsedInputValues];
  };

  // 5. Order inputs based on inputOrder
  const orderInputs = (finalInputs) => {
    const inputsByName = {};
    finalInputs.forEach((input) => (inputsByName[input.name] = input));
    const finalInputsList = new InputsList(inputsByName, inputOrder);
    return finalInputsList.getSortedList();
  };

  const fullContentByName = {};
  if (fullDynamicContentList) {
    fullDynamicContentList.forEach((dynamicContent) => (fullContentByName[dynamicContent.name] = dynamicContent));
  }
  const finalContentList = getFinalContentList(fullContentByName, taggedDynamicContentNames, parsedInputValues);
  const [finalInputList, finalInputValues] = getFinalInputList(
    finalContentList,
    data?.inputsById,
    data?.inputIdsByDynamicContentId,
    data?.nestedContentById,
  );
  // Special case for presentations where we have a full input list and inputValues list already populated and just
  // need to prefill
  let orderedInputList;
  if (
    finalInputList.size === 0 &&
    Object.keys(finalInputValues).length > 0 &&
    Object.keys(existingInputs || {}).length > 0
  ) {
    orderedInputList = orderInputs(Object.values(existingInputs));
  } else {
    orderedInputList = orderInputs(finalInputList);
  }

  return {
    isLoading,
    isError,
    error,
    orderedInputList,
    inputValues: finalInputValues,
    invalidateAll,
  };
};

/**
 * Get full list of inputs from a given query string
 * @param queryString Query string
 * @param contentType Content type to differentiate between conditional and other types of content
 * @param inputValues
 * @returns {{isLoading: *, inputsById: *, isError: *, error: *}}
 */
export const useQueryInputList = (queryString, contentType, inputValues) => {
  const queryClient = useQueryClient();

  const filterInputsBasedOnInputValues = (inputsByName, inputNamesByContentId, nestedContentById) => {
    /** Get all inputs referenced by the given content, including any nested references */
    const getReferencedInputNames = (contentId, checkedContent = new Set()) => {
      // cyclic reference check
      if (checkedContent.has(contentId)) {
        return [];
      }
      checkedContent.add(contentId);

      const referencedNames = [];
      if (inputNamesByContentId[contentId]) {
        referencedNames.push(...inputNamesByContentId[contentId]);
      }
      const nestedContent = nestedContentById[contentId];
      nestedContent?.forEach((nestedId) => {
        const nestedInputs = getReferencedInputNames(nestedId, checkedContent);
        referencedNames.push(...nestedInputs);
      });
      return [...new Set(referencedNames)];
    };

    if (contentType === Constants.DynamicContentTypes.CONDITIONAL && queryString) {
      const conditionalContentProcessor = new ConditionalContentProcessor(
        utils.safeJSONParse(queryString),
        inputValues || {},
      );

      const outputContentId = conditionalContentProcessor.getOutputContent();
      const inputNames = getReferencedInputNames(outputContentId);
      const finalInputsByName = {};
      // Grab the input names from the content plus the inputs that exist in the conditional query.
      inputNames.forEach((inputName) => (finalInputsByName[inputName] = inputsByName[inputName]));
      queryInputNames
        .filter((inputName) => !!inputsByName[inputName])
        .forEach((inputName) => (finalInputsByName[inputName] = inputsByName[inputName]));
      return finalInputsByName;
    } else {
      return inputsByName;
    }
  };

  const handleNewInputs = (queryInputNames, inputsByName) => {
    const finalInputsByName = inputsByName ? { ...inputsByName } : {};
    if (queryInputNames) {
      queryInputNames.forEach((inputName) => {
        if (!finalInputsByName[inputName]) {
          finalInputsByName[inputName] = {
            name: inputName,
            type: Constants.InputTypes.STRING,
            source_type: Constants.InputSources.USER_INPUT,
            description: '',
            isNew: true,
            loops: [],
          };
        }
      });
    }

    return finalInputsByName;
  };

  // 1. Parse query to get all inputs
  const queryInputNames = getInputNamesFromQueryString(queryString, contentType);
  // 2. Parse query to get any nested dynamic content
  const nestedContentNames = getNestedContentFilterFromQueryString(queryString, contentType, inputValues);
  // 3. Fetch inputs from inputs and nested content
  const queryKey = ['query_inputs', queryInputNames, nestedContentNames];
  const { isLoading, isError, data, error } = useQuery({
    queryKey: queryKey,
    queryFn: () =>
      new Promise((resolve, reject) => {
        if (queryInputNames.length === 0 && nestedContentNames.length === 0) {
          return resolve({ inputsById: {} });
        }
        return API.post(
          '/queries/parameters/',
          { nested_content_filter: nestedContentNames, query_param_names: queryInputNames },
          (response) =>
            resolve({
              inputsByName: response.data.params_by_name,
              inputNamesByContentId: response.data.param_names_by_content_id,
              nestedContentById: response.data.nested_content_by_id || {},
            }),
          (err) => reject(err),
        );
      }),
  });
  const inputsIncludingNewInputs = handleNewInputs(queryInputNames, data?.inputsByName);
  let finalInputsByName = inputsIncludingNewInputs;
  if (contentType === Constants.DynamicContentTypes.CONDITIONAL && data?.inputsByName) {
    finalInputsByName = filterInputsBasedOnInputValues(
      data?.inputsByName,
      data?.inputNamesByContentId,
      data?.nestedContentById,
    );
  }

  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey });
  };

  return {
    isLoading,
    isError,
    error,
    inputsByName: inputsIncludingNewInputs,
    filteredInputsByName: finalInputsByName,
    invalidate,
  };
};

export const getInputNamesFromQueryString = (queryString, contentType) => {
  const inputNames = new Set();
  if (contentType === Constants.DynamicContentTypes.CONDITIONAL) {
    const queryObj = utils.safeJSONParse(queryString);
    if (queryObj.conditions) {
      for (let condition of queryObj.conditions) {
        for (let clause of condition.clauses) {
          inputNames.add(clause.param);
        }
      }
    }
  } else {
    const inputRegex = new RegExp(Constants.INPUT_REGEX_PATTERN);
    let match = inputRegex.exec(queryString);
    while (match) {
      const inputName = match[1];
      if (inputName) {
        inputNames.add(inputName);
      }
      match = inputRegex.exec(queryString);
    }
  }

  return Array.from(inputNames);
};

const getNestedContentFilterFromQueryString = (queryString, contentType, inputValues) => {
  if (!queryString) {
    return [];
  }
  if (contentType === Constants.DynamicContentTypes.CONDITIONAL) {
    const conditionalContentProcessor = new ConditionalContentProcessor(utils.safeJSONParse(queryString), inputValues);

    return { id: conditionalContentProcessor.getAllContentNames() };
  }

  const contentRegex = new RegExp(Constants.NESTED_CONTENT_REGEX_PATTERN);
  let match = contentRegex.exec(queryString);
  let nestedContent = [];
  while (match) {
    const contentName = match[1];
    if (contentName) {
      nestedContent.push(contentName);
    }
    match = contentRegex.exec(queryString);
  }
  return { name: nestedContent };
};
