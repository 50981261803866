import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/lib/Icon';
import API from 'lib/api';
import Pluralize from 'pluralize';
import { MConfirm } from './Alerts';
import Banner, { toastBanner } from './Banner';
import Button from 'components/lib/Button';
import assistant from 'lib/asssistant';
import { useBulkDynamicContentMutator } from '../../lib/hooks/useDynamicContent';

function BulkExplainerButton({ selectedDynamicContent }) {
  const [isLoading, setIsLoading] = useState(false);
  const { bulkUpdateDescription } = useBulkDynamicContentMutator();

  const onBulkExplainer = () => {
    if (selectedDynamicContent.length < 1) {
      return false;
    }

    setIsLoading(true);
    const queryIds = selectedDynamicContent.map((content) => content.query_obj.id);
    const onSuccess = (result, onComplete) => {
      if (result.data.status === 'done') {
        bulkUpdateDescription(
          selectedDynamicContent.map((content) => content.id),
          result.data.result,
        )
          .then((response) => {
            bulkUpdateContentToast(response.data.updated);
          })
          .catch(API.defaultError);
        onComplete();
        setIsLoading(false);
      }
    };

    const onError = (err) => {
      setIsLoading(false);
      API.defaultError(err);
    };

    assistant.explainQueryBulk(queryIds, onSuccess, onError);
  };

  const warningMessage = `You will generate descriptions for ${Pluralize('piece', selectedDynamicContent.length, true)}
     of dynamic content. Any existing descriptions will be overwritten. This cannot be undone.`;

  const onClick = () => {
    MConfirm(
      'Generate Descriptions with AI',
      warningMessage,
      'warning',
      (ok) => {
        ok && onBulkExplainer();
      },
      'Generate',
    );
  };

  const bulkUpdateContentToast = (updated) => {
    toastBanner(
      <Banner bannerType="success" text={`Successfully updated ${Pluralize('item', updated.length, true)}`} />,
    );
  };

  return (
    <Button category="secondary" onClick={onClick} status={isLoading ? 'loading' : ''}>
      <Icon name="aicolor" size={16} theme="regular" />
      <span className="ml-2.5">Generate Description with AI</span>
    </Button>
  );
}

BulkExplainerButton.propTypes = {
  selectedDynamicContent: PropTypes.array,
};

export default BulkExplainerButton;
