import React from 'react';
import Pluralize from 'pluralize';
import Banner, { toastBanner } from 'components/shared/Banner';
import Constants from 'components/Constants';

export const updateSuccessBanner = ({ itemIds, permission, accessorsToAdd }) => {
  const successMessage = _addAccessSuccessMessage(itemIds, permission, accessorsToAdd);
  toastBanner(<Banner bannerType="success" text={successMessage} />);
};

export const updateErrorBanner = ({ itemIds, permission, accessorsToAdd, err }) => {
  const headline = _addAccessFailureHeadline(itemIds, permission, accessorsToAdd);
  const subline = _addAccessFailureSubline(err);
  toastBanner(<Banner bannerType="error" text={headline} sublineText={subline} />);
};

export const deleteSuccessBanner = ({ accesses, reassignedItems }) => {
  toastBanner(
    <Banner
      bannerType="success"
      text={`Successfully changed access for ${Pluralize('item', accesses.length, true)}`}
    />,
  );

  Object.entries(reassignedItems).forEach(([item_type, ids]) => {
    let text;
    if (ids.length === 1) {
      text = `1 ${
        item_type === 'dynamic_content' ? 'piece of dynamic content' : item_type === 'parameter' ? 'input' : 'item'
      } has`;
    } else {
      text = `${ids.length} ${
        item_type === 'dynamic_content' ? 'pieces of dynamic content' : item_type === 'parameter' ? 'inputs' : 'items'
      } have`;
    }
    toastBanner(<Banner bannerType="info" text={`Ownership of ${text} been transferred to you.`} />);
  });
};

function _describe_accessors(accessors) {
  const numUsers = accessors.filter((accessor) => accessor.accessor_type === 'user').length;
  const numGroups = accessors.filter((accessor) => accessor.accessor_type === 'group').length;
  let description = '';
  if (numUsers) {
    description += Pluralize('user', numUsers, true);
  }
  if (numGroups) {
    if (description) {
      description += ' and ';
    }
    description += Pluralize('group', numGroups, true);
  }
  return description;
}
function _addAccessMessagePrepositionalPhrase(accessorsToAdd, numItems) {
  const numAccessors = accessorsToAdd.length;
  const accessorDescription = _describe_accessors(accessorsToAdd);
  if (numAccessors === 1) {
    return `to ${Pluralize('item', numItems, true)}`;
  } else if (numItems === 1) {
    return `to ${accessorDescription}`;
  } else {
    return `for ${Pluralize('item', numItems, true)} to ${accessorDescription}`;
  }
}

function _addAccessSuccessMessage(itemIds, permission, accessorsToAdd) {
  const numItems = itemIds.length;
  let prepositionalPhrase = _addAccessMessagePrepositionalPhrase(accessorsToAdd, numItems);
  let message = `Granted ${permission} access ${prepositionalPhrase}`;
  if (permission === Constants.PERMISSIONS.owner.value) {
    message += `, and granted ${Constants.PERMISSIONS.edit.value} access to the former item ${Pluralize(
      'owner',
      numItems,
      false,
    )}`;
  }
  return message;
}

function _addAccessFailureHeadline(itemIds, permission, accessorsToAdd) {
  const numItems = itemIds.length;
  let prepositionalPhrase = _addAccessMessagePrepositionalPhrase(accessorsToAdd, numItems);
  return `An error occurred granting ${permission} access ${prepositionalPhrase}:`;
}

function _addAccessFailureSubline(err) {
  if (err && err.response && err.response.status) {
    if (
      (err.response.status === 400 || err.response.status === 409) &&
      err.response.data &&
      err.response.data.message
    ) {
      return err.response.data.message;
    } else {
      return 'Something went wrong! Please reload the page and try again.';
    }
  }
}
