import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ErrorPill } from '../../svg/error_pill.svg';
import { ReactComponent as ImageOff } from '../../svg/image_off.svg';

export default function DisconnectedTemplateSlide({ height = 0, showingSingleSlide }) {
  height = height > 0 ? height : undefined;
  const bottom = showingSingleSlide ? '22px' : undefined;
  return (
    <div className="empty-slide" style={{ height }}>
      <ErrorPill className="error-pill" />
      <div className="center-message" style={{ bottom }}>
        <ImageOff />
        <p>Slide not found</p>
      </div>
    </div>
  );
}

DisconnectedTemplateSlide.propTypes = {
  height: PropTypes.number,
  showingSingleSlide: PropTypes.bool,
};
