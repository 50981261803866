import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/lib/Icon';
import { MAlert } from './Alerts';
import API from '../../lib/api';
import assistant from '../../lib/asssistant';
import { useDynamicContentMutator, useOneDynamicContent } from '../../lib/hooks/useDynamicContent';
import { cloneDeep } from 'lodash';
import Button from '../lib/Button';

const ReactTableDescriptionDropdown = ({ cellRef, rowData, onClose }) => {
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const { updateDescription: updateContent } = useDynamicContentMutator();
  const { dynamicContent } = useOneDynamicContent(rowData?.id);

  const popupRef = useRef();

  useEffect(() => {
    if (cellRef) {
      const rect = cellRef.getBoundingClientRect();
      const parentRect = document.body.getBoundingClientRect();
      let anchorY = rect.bottom;
      const spaceAbove = rect.top - parentRect.top;
      const spaceBelow = parentRect.bottom - rect.bottom;
      if (spaceBelow < spaceAbove) {
        anchorY = rect.top + window.scrollY - popupRef?.current?.clientHeight;
      }

      setPosition({
        top: anchorY,
        left: rect.left + window.scrollX,
      });

      setIsVisible(true);
    }
  }, [cellRef]);

  const explainQuery = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    const onSuccess = (response, onComplete) => {
      if (response.data.status === 'done') {
        onComplete();
        const updatedDynamicContent = cloneDeep(dynamicContent);
        updatedDynamicContent.description = response.data.result;
        updateContent(updatedDynamicContent.id, updatedDynamicContent)
          .then(() => onClose(null))
          .catch(onError);
        setIsLoading(false);
      } else if (response.data.status === 'error') {
        setIsLoading(false);
        MAlert(`Error generating description: ${response.data.message}`, 'Error', 'error');
        onComplete();
      }
    };
    const onError = (err) => {
      setIsLoading(false);
      API.defaultError(err);
    };
    assistant.explainQuery(rowData.query_obj, rowData.name, 'dynamic_content', onSuccess, onError);
  };

  const handleModalClick = (e) => {
    e.stopPropagation();
    onClose(null);
  };

  return (
    <>
      <div className="modal is-active h-screen w-screen cursor-default" onClick={handleModalClick} />
      <div
        className={`${
          isVisible ? '' : 'invisible'
        } fixed z-50 w-[400px] mx-3 bg-matik-white rounded shadow-md border border-grey-300 divide-y divide-grey-300`}
        style={{
          top: `${position.top}px`,
          left: `${position.left}px`,
        }}
        ref={popupRef}
      >
        {rowData?.description && (
          <div className="p-4 max-h-44 overflow-y-scroll" onClick={(e) => e.stopPropagation()}>
            {rowData.description}
          </div>
        )}
        <div role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
          <Button
            className="flex items-center px-4 py-3 my-1 text-sm w-full text-left text-matik-black hover:bg-grey-200"
            role="menuitem"
            onClick={(e) => explainQuery(e)}
            status={isLoading ? 'loading' : 'default'}
            category="none"
          >
            <div className="flex gap-3 items-center">
              <Icon name="aicolor" size={16} theme="regular" />
              Update Description with AI
            </div>
          </Button>
        </div>
      </div>
    </>
  );
};

ReactTableDescriptionDropdown.propTypes = {
  cellRef: PropTypes.object,
  rowData: PropTypes.object,
  onClose: PropTypes.func,
};

export default ReactTableDescriptionDropdown;
