import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory, Link, useRouteMatch } from 'react-router-dom';
import { cloneDeep, map } from 'lodash';
import API from 'lib/api';
import ProgressBar from 'components/shared/ProgressBar';
import { flowTypes, runStatus } from './Constants';
import moment from 'moment';
import Constants from 'components/Constants';
import Icon from 'components/lib/Icon';
import utils from 'lib/utils';
import RunAnalyticsModal from './RunAnalyticsModal';
import { openFullScreenModal } from 'redux/ui/action';
import Button from 'components/lib/Button';

function FlowsSidepaneRun({ fetchRuns, flow, run }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const [isOpen, setIsOpen] = useState(run.status === runStatus.NOT_STARTED || flow.flow_type === flowTypes.MANUAL);
  const templateType =
    run.template.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL
      ? 'email'
      : Constants.TEMPLATE_SOURCE_TYPES.DOCUMENT_TYPES.includes(run.template.source_type)
      ? 'document'
      : Constants.TEMPLATE_SOURCE_TYPES.SPREADSHEET_TYPES.includes(run.template.source_type)
      ? 'spreadsheet'
      : 'presentation';
  const linkUrl = match.path.includes('create')
    ? `/create/presentations?bulk=${run.id}`
    : `/presentations?bulk=${run.id}`;
  const progressBarOrder =
    templateType === 'email'
      ? ['sent', 'sent_override', 'unsent_error', 'unsent_condition']
      : ['done', 'warning', 'error', 'failed_condition'];

  const statusVerbiage = {
    done: 'Generated',
    warning: 'Generated (Warnings)',
    error: 'Not Generated (Error)',
    failed_condition: 'Not Generated (Conditions)',
    sent: 'Sent',
    sent_override: 'Sent (Manual Override)',
    unsent_error: 'Not Sent (Warning/Error)',
    unsent_condition: 'Not Sent (Conditions)',
  };

  const engagementVerbiage = {
    opens_pct: 'Opened',
    clicks_pct: 'Clicked',
    bounces_pct: 'Bounced',
    unsubscribes_pct: 'Unsubscribed',
    spam_reports_pct: 'Spam Report',
  };

  const engagementTooltips = {
    opens_pct: 'Percentage of sent emails that were opened.',
    clicks_pct: 'Percentage of sent emails where the recipient clicked a link.',
    bounces_pct: 'Percentage of sent emails that bounced.',
    unsubscribes_pct: 'Percentage of sent emails where the recipient unsubscribed.',
    spam_reports_pct: 'Percentage of sent emails that a recipient marked as spam.',
  };

  const statusTooltips = {
    sent: 'Emails that were sent successfully.',
    sent_override: 'Emails initially excluded based on Send Conditions, that were later sent via manual override.',
    unsent_error: 'Emails not sent due to issues during generation.',
    unsent_condition: 'Emails excluded from sending based on Send Conditions.',
    done: utils.capitalizeString(templateType) + 's that were generated successfully.',
    warning: utils.capitalizeString(templateType) + 's generated with warning(s).',
    error: utils.capitalizeString(templateType) + 's that were not generated due to error(s).',
    failed_condition: utils.capitalizeString(templateType) + 's not generated based on Generation Conditions.',
  };

  const toggleOpen = (e) => {
    if (e.target.className !== 'button is-secondary') {
      setIsOpen((prev) => !prev);
    }
  };

  const renderStatusDetail = () => {
    if (run.presentation_status_data && run.status !== runStatus.ERROR) {
      let generatedCount = 0;
      const presentationsDetail = map(progressBarOrder, (status, idx) => {
        const count = run.presentation_status_data[status];
        generatedCount += count;
        return (
          <div key={idx} className="presentations-detail mrl">
            <div className={`indicator ${status} mls`}></div>
            <div
              data-tooltip-id="matik-tooltip"
              data-tooltip-content={statusTooltips[status]}
              className="status-name mls"
            >
              {statusVerbiage[status]}
            </div>
            <div className="count mls">{count}</div>
          </div>
        );
      });
      const remainder = Math.max(run.presentation_status_data.total - generatedCount, 0);
      if ([runStatus.PROCESSING, runStatus.CANCELED].includes(run.status) && remainder) {
        presentationsDetail.push(
          <div key={progressBarOrder.length} className="presentations-detail">
            <div className="indicator remaining mls"></div>
            <div
              data-tooltip-id="matik-tooltip"
              data-tooltip-content={utils.capitalizeString(templateType) + 's that are still processing.'}
              className="status-name mls"
            >
              Remaining
            </div>
            <div className="count mls">{remainder}</div>
          </div>,
        );
      }
      return presentationsDetail;
    }
  };

  const renderEngagementDetail = () => {
    if (
      run.status !== runStatus.NOT_STARTED &&
      run.status !== runStatus.ERROR &&
      Object.keys(run.sendgrid_data).length
    ) {
      const engagementDetail = map(Object.keys(engagementVerbiage), (status, idx) => {
        return (
          <div key={idx} className="presentations-detail">
            <div
              data-tooltip-id="matik-tooltip"
              data-tooltip-content={engagementTooltips[status]}
              className="status-name mls"
            >
              {engagementVerbiage[status]}
            </div>
            <div className="count mls">{`${run.sendgrid_data[status]}%`}</div>
          </div>
        );
      });
      return engagementDetail;
    }
    return null;
  };

  const renderRunStatusSentence = () => {
    const date = moment
      .utc(run.completed_on ?? run.created_on)
      .local()
      .format('LLL');
    let sentence;
    if (run.status === runStatus.DONE) {
      sentence = `The run was finished ${date}`;
    } else if (run.status === runStatus.NOT_STARTED && run?.presentations?.length) {
      sentence = 'Only the preview was generated.';
    } else if (run.status === runStatus.NOT_STARTED) {
      sentence = `The run will start ${date}.`;
    } else if (run.status === runStatus.CANCELED) {
      sentence = `The run was canceled ${date}.`;
    } else if (run.status === runStatus.ERROR) {
      sentence = `The run was not started with the following error: ${run.status_message}`;
    } else {
      sentence = `This run was started ${date}`;
    }
    return <div className="helper-text">{sentence}</div>;
  };

  const cancel = () => {
    if (run.id && run.status === runStatus.PROCESSING) {
      API.put(
        `/bulk_presentations/${run.id}/`,
        { status: runStatus.CANCELED },
        () => {
          run.status = runStatus.CANCELED;
          fetchRuns();
        },
        API.defaultError,
      );
    }
  };

  const onDetailsClick = () => {
    if (templateType === 'email') {
      dispatch(openFullScreenModal(`runAnalyticsModal${run.id}`));
    } else {
      history.push(linkUrl);
    }
  };

  const restartRun = () => {
    const bulkData = cloneDeep(run.params);
    bulkData['scheduled_task_id'] = flow.id;
    bulkData['name'] = `${flow.name} ${flow.runs.length + 1}`;

    API.post(
      '/bulk_presentations/',
      bulkData,
      (response) => {
        API.post(
          `/bulk_presentations/${response.data.id}/start/`,
          {},
          () => {
            flow.runs.push(response.data.id);
            fetchRuns();
          },
          API.defaultError,
        );
      },
      API.defaultError,
    );
  };

  const fetchCsvData = (bulk_presentation_id) => {
    API.requestWithDownload('get', `/bulk_presentations/${bulk_presentation_id}/email_csv/`, 'blob', (response) => {
      const blob = response.data;
      const reader = new FileReader();
      reader.onload = (event) => {
        const a = document.createElement('a');
        a.href = event.target.result; // eslint-disable-line scanjs-rules/assign_to_href
        a.download = `bulk_email_${bulk_presentation_id}_data.csv`;
        a.click();
      };
      reader.readAsDataURL(blob);
    });
  };

  const renderActions = () => {
    const viewText =
      templateType === 'email'
        ? 'View Details'
        : templateType === 'document'
        ? 'View Documents'
        : templateType === 'spreadsheet'
        ? 'View Spreadsheets'
        : 'View Presentations';
    switch (run.status) {
      case runStatus.DONE:
        return (
          <>
            <Button onClick={onDetailsClick} type="button" category="secondary">
              {viewText}
            </Button>
            {templateType === 'email' && (
              <Button category="secondary" type="button" onClick={() => fetchCsvData(run.id)}>
                <Icon name="arrow_download" size={16} theme="regular" />
                <span className="ml-2">CSV</span>
              </Button>
            )}
            {flow.flow_type === flowTypes.SCHEDULED && (
              <Button type="button" category="secondary" onClick={restartRun}>
                Restart Run
              </Button>
            )}
          </>
        );
      case runStatus.CANCELED:
        return (
          <>
            <Link to={linkUrl} className="button is-secondary">
              {viewText}
            </Link>
            {flow.flow_type === flowTypes.SCHEDULED && (
              <Button type="button" category="secondary" onClick={restartRun}>
                Restart Run
              </Button>
            )}
          </>
        );
      case runStatus.PROCESSING:
        return (
          <>
            <Link to={linkUrl} className="button is-secondary">
              {viewText}
            </Link>
            <Button type="button" category="secondary" onClick={cancel}>
              Cancel Run
            </Button>
          </>
        );
      default:
        return;
    }
  };

  const progressBar = (
    <ProgressBar
      runError={run.status === runStatus.ERROR}
      entitiesMapping={run.presentation_status_data}
      length={run?.presentation_status_data?.total}
      progressBarOrder={progressBarOrder}
      statusVerbiage={statusVerbiage}
    />
  );

  const chevron = isOpen ? 'chevron_up' : 'chevron_down';

  return (
    <div className="run-details" onClick={toggleOpen}>
      <h2>
        {run.name}
        <Icon name={chevron} size={16} theme="regular" />
      </h2>
      {progressBar}
      {isOpen && (
        <>
          <div className="presentations-detail-wrapper">
            <div>{renderStatusDetail()}</div>
            <div>{renderEngagementDetail()}</div>
          </div>
          {renderRunStatusSentence()}
          <div className="run-actions">{renderActions()}</div>
        </>
      )}
      <RunAnalyticsModal
        run={run}
        templateType={templateType}
        progressBar={progressBar}
        statusDetail={renderStatusDetail()}
        fetchCsvData={fetchCsvData}
      />
    </div>
  );
}

FlowsSidepaneRun.propTypes = {
  flow: PropTypes.object,
  run: PropTypes.object,
  fetchRuns: PropTypes.func,
};

export default FlowsSidepaneRun;
