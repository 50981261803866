import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Tile } from 'react-bulma-components';
import QueryForm from './QueryForm';
import ApiForm from './ApiForm';
import DataSourceSelect from './DataSourceSelect';
import DataSourceFormWrapper from './DataSourceFormWrapper';
import { DynamicContentContext } from 'components/producer/dynamicContent/DynamicContentContext';

function InnerDynamicContentForm({
  allDynamicContent,
  currentDataSource,
  dynamicContentMethod,
  formRef,
  helpText,
  isLoading,
  onDataSourceSelect,
  onMethodChange,
  onOutputTypeSelect,
  inputsInQueryString,
  columnNameError,
  testResult,
  updateTestResult,
  validateFormData,
  validationErrors,
}) {
  const dynamicContentContext = useContext(DynamicContentContext);

  let formBody = null;
  if (dynamicContentMethod === 'query') {
    formBody = (
      <QueryForm
        inputsInQueryString={inputsInQueryString}
        dataSource={currentDataSource}
        validateFormData={validateFormData}
        entityId={dynamicContentContext.currentDynamicContent ? dynamicContentContext.currentDynamicContent.id : 'new'}
        entityType="dynamic_content"
      />
    );
  } else if (dynamicContentMethod === 'api') {
    // Assemble the list of dynamicContent - for use in autocompleting nested content entries
    const unordered = {};
    const contents = {};
    if (allDynamicContent) {
      for (let content of allDynamicContent) {
        // Exclude the current content from the list to prevent cycles
        if (content !== dynamicContentContext.currentDynamicContent) {
          unordered[content.name] = content;
        }
      }

      Object.keys(unordered)
        .sort()
        .forEach(function (key) {
          contents[key] = unordered[key];
        });
    }

    formBody = (
      <ApiForm
        dataSource={currentDataSource}
        contents={contents}
        inputsInQueryString={inputsInQueryString}
        isLoading={isLoading}
        allDynamicContent={allDynamicContent}
        validateFormData={validateFormData}
        validationErrors={validationErrors}
        columnNameError={columnNameError}
        entityId={dynamicContentContext.currentDynamicContent ? dynamicContentContext.currentDynamicContent.id : 'new'}
        entityType="dynamic_content"
        updateTestResult={updateTestResult}
        formRef={formRef}
      />
    );
  }

  return (
    <Tile kind="ancestor">
      <Tile kind="parent" vertical>
        <Tile kind="child" className="box is-shadowless ptn pln prn pbn has-light-gray-border">
          <DataSourceSelect
            selectedDataSource={currentDataSource}
            isReadOnly={dynamicContentContext.isReadOnly}
            onDataSourceSelect={onDataSourceSelect}
          />
        </Tile>
        {currentDataSource && (
          <DataSourceFormWrapper
            currentDataSource={currentDataSource}
            dynamicContentMethod={dynamicContentMethod}
            formBody={formBody}
            helpText={helpText}
            onMethodChange={onMethodChange}
            onOutputTypeSelect={onOutputTypeSelect}
            testResult={testResult}
          />
        )}
      </Tile>
    </Tile>
  );
}

InnerDynamicContentForm.propTypes = {
  allDynamicContent: PropTypes.array,
  currentDataSource: PropTypes.object,
  dynamicContentMethod: PropTypes.string,
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  entityType: PropTypes.string,
  isLoading: PropTypes.bool,
  onDataSourceSelect: PropTypes.func,
  onMethodChange: PropTypes.func,
  onOutputTypeSelect: PropTypes.func,
  inputsInQueryString: PropTypes.object,
  columnNameError: PropTypes.string,
  testResult: PropTypes.object,
  validateFormData: PropTypes.func,
  validationErrors: PropTypes.object,
  updateTestResult: PropTypes.func,
  formRef: PropTypes.object,
  helpText: PropTypes.string,
};

export default InnerDynamicContentForm;
