export const parseSortString = (sortString) => {
  // Sort strings are generally `${field} ${direction} ${pivotIndex}` though direction and pivot Index are optional
  // and tableau allows spaces in field names which is really vexing.
  let parts = sortString.split(' ');
  let field = '';
  let direction = '';
  let pivotIndex = '';
  if (parts.length > 1 && !isNaN(parts[parts.length - 1])) {
    pivotIndex = parts.pop();
  }
  if (parts.length > 1 && ['asc', 'desc'].includes(parts[parts.length - 1].toLowerCase())) {
    direction = parts.pop().toLowerCase();
  }
  field = parts.join(' ');
  return { field, direction, pivotIndex };
};
