import utils from './utils';
import moment from 'moment';

const scheduler = {
  TaskType: {
    SCHEDULED_BULK_PRESENTATIONS: 'scheduled_bulk_presentations',
  },
  ScheduleType: {
    DAY_OF_MONTH: 'day_of_month',
    DAY_OF_WEEK: 'day_of_week',
    EVERY_DAY: 'every_day',
  },
  RecurrenceType: {
    MONTHLY: 'monthly',
    WEEKLY: 'weekly',
    DAILY: 'daily',
  },
  Days: [
    { label: 'S', fullLabel: 'Sunday', value: 7 },
    { label: 'M', fullLabel: 'Monday', value: 1 },
    { label: 'T', fullLabel: 'Tuesday', value: 2 },
    { label: 'W', fullLabel: 'Wednesday', value: 3 },
    { label: 'T', fullLabel: 'Thursday', value: 4 },
    { label: 'F', fullLabel: 'Friday', value: 5 },
    { label: 'S', fullLabel: 'Saturday', value: 6 },
  ],
  RecurrenceMapping: {
    monthly: 'months',
    weekly: 'weeks',
    daily: 'days',
  },
  getSentenceFromSchedule: (scheduledTask, withLocalTime) => {
    let sentence = 'Runs ';
    if (scheduledTask.recurrence_period === 1) {
      sentence += scheduledTask.recurrence_type;
    } else if (scheduledTask.recurrence_period) {
      sentence += `every ${scheduledTask.recurrence_period} ${
        scheduler.RecurrenceMapping[scheduledTask.recurrence_type]
      }`;
    }
    if (scheduledTask.schedule_type === scheduler.ScheduleType.DAY_OF_MONTH) {
      if (scheduledTask.schedule_target === 31) {
        sentence += ' on the last day';
      } else {
        sentence += ` on the ${utils.getOrdinalNum(scheduledTask.schedule_target)}`;
      }
    } else if (scheduledTask.schedule_type === scheduler.ScheduleType.DAY_OF_WEEK) {
      sentence += ` on ${scheduler.getDayFromNum(scheduledTask.schedule_target)}`;
    }
    if (withLocalTime) {
      const hour = moment.utc(scheduledTask.effective_start).local().hour();
      sentence += ` at ${hour > 12 ? hour % 12 : hour === 0 ? 12 : hour}:00${hour >= 12 ? 'pm' : 'am'} Local Time.`;
    }
    return sentence;
  },

  getDefaultScheduleName: (templateName, recurrenceType) => {
    return `${templateName} - ${recurrenceType}`;
  },

  getDayFromNum: (num) => {
    for (let day of scheduler.Days) {
      if (num === day.value) {
        return day.fullLabel;
      }
    }
    return '';
  },
};

export default scheduler;
