import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { ContentNameCell, InputTypeCell } from '../../shared/FullPageListCells';
import moment from 'moment';
import WithFilterListHeader from '../../shared/WithFilterListHeader';
import FullPageList from '../../shared/FullPageList';
import Constants from '../../Constants';
import { Box } from 'react-bulma-components';
import { createColumnHelper } from '@tanstack/react-table';
import localeDateFormatterUtils from 'lib/localeDate';

const PageWithFilterHeader = WithFilterListHeader(FullPageList);

class InputsList extends Component {
  render() {
    const columnHelper = createColumnHelper();

    const columns = [
      columnHelper.accessor('source_type', {
        header: 'Type',
        cell: InputTypeCell,
        meta: {
          width: '70px',
        },
      }),
      columnHelper.accessor('name', {
        header: 'Name',
        cell: ContentNameCell,
      }),
      columnHelper.accessor(
        (d) => {
          const locale = localeDateFormatterUtils.getUserLocale();
          const localeDateFormat = localeDateFormatterUtils.getLocaleDateFormatStringFromLocale(locale);
          return moment(d.updated_on).format(localeDateFormat);
        },
        {
          id: 'updated_on',
          header: 'Last Updated',
          meta: {
            width: '145px',
          },
        },
      ),
    ];

    return (
      <Box className="is-shadowless is-paddingless is-fullheight">
        <PageWithFilterHeader
          entities={this.props.inputs}
          newEntityUrl="/inputs/new"
          type="input"
          title="Your Inputs"
          buttonClass="is-secondary"
          selectedRow={this.props.selectedInput}
          newEntityButton="Add Input"
          pagination={this.props.pagination}
          fetchItems={this.fetchItems}
          filterPlaceholder="Search Inputs"
          searchState={this.props.searchState}
          searchAttributes={this.props.searchAttributes}
          hideTopPagination={true}
          onRowClick={this.onRowClick}
          scrollInElement={true}
          loading={this.props.isPaginating}
          columns={columns}
          isSidebar={true}
          isSearching={this.props.isSearching}
          displayFilterHeader={true}
          deletedItems={this.props.deletedItems}
        />
      </Box>
    );
  }

  onRowClick = (inputId) => {
    this.props.history.push(`/inputs/${inputId}`);
  };

  fetchItems = (page, sort = null) => {
    const offset = page * Constants.PAGE_SIZE;
    this.props.fetchItems(offset, Constants.PAGE_SIZE, sort);
  };
}

InputsList.propTypes = {
  fetchItems: PropTypes.func,
  history: PropTypes.object,
  isPaginating: PropTypes.bool,
  isSearching: PropTypes.bool,
  pagination: PropTypes.object,
  inputs: PropTypes.array,
  searchAttributes: PropTypes.object,
  searchState: PropTypes.object,
  selectedInput: PropTypes.object,
  deletedItems: PropTypes.array,
};

export default withRouter(InputsList);
