import LongRequest from './longRequest';

const assistant = {
  explainQuery: (query, name, queryUse, onSuccess, onError) => {
    const explainRequest = new LongRequest(`/queries/${query.id || 'new'}/explain`);
    explainRequest.post(
      {
        query_string: query.query_string,
        query_use: queryUse,
        name: name,
      },
      onSuccess,
      onError,
    );
  },

  explainQueryBulk: (queryIds, onSuccess, onError) => {
    const explainRequest = new LongRequest('/queries/explain/bulk');
    explainRequest.post({ query_ids: queryIds }, onSuccess, onError);
  },
};

export default assistant;
