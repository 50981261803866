import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import { isEmpty, isEqual, find } from 'lodash';
import draftToHtml from 'draftjs-to-html';
import parse from 'html-react-parser';
import ReactDOMServer from 'react-dom/server';

import inputsLib from 'lib/inputs';
import utils from 'lib/utils';
import teams from 'lib/teams';
import API from 'lib/api';
import { getLoopedInfo } from 'lib/looping';

import { ReactComponent as BulkLogo } from 'svg/bulk.svg';
import { ReactComponent as InputMappingIcon } from 'images/input_mapping.svg';

import Constants from 'components/Constants';
import { EndUserCreateContext } from 'components/consumer/EndUserCreateContext';
import withUserContext from 'components/shared/WithUserContext';
import AuthTokenInput from 'components/shared/AuthTokenInput';
import QueryLoading from 'components/shared/QueryLoading';
import UpsellGenericModal from 'components/payments/UpsellGenericModal';
import { MProductUpsell } from 'components/shared/Alerts';
import TabHeader from 'components/producer/templates/TabHeader';
import Button from 'components/lib/Button';
import IconPill from 'components/lib/IconPill';
import MatikUserInputField from 'components/shared/paramFields/MatikUserInputField';
import CsvBulkUploadForm from 'components/shared/presentations/CsvBulkUploadForm';

const TABS = { INPUTS: 'inputs', UPLOAD: 'upload' };

function FormFromInputs({
  activeTab,
  handleTabClick,
  isReadOnly,
  userContext,
  test,
  template,
  attachedTemplate,
  dataSourcesWithAuthToken,
  inputs,
  inputValues,
  setUserBasedInputsIsLoading,
  isPresentationView,
  allowBulk,
  isProducer,
  currentBulkInputName,
  isTestSlide,
  allDynamicContent,
  tokenError,
  tokenSuccess,
  tokenLoading,
  onTestAccess,
  onInputValueChange,
  setAuthTokenByDynamicContent,
  onBulkInputToggle,
  slideIdsByInputName = {},
  authTokenByDynamicContent,
}) {
  const currentInputValuesRef = useRef();
  currentInputValuesRef.current = inputValues;

  const handleInputOptionsFromQuery = (
    inputName,
    options,
    inputType,
    nestedInputValues,
    mappedOptions,
    inputMappingOptions,
    isStillLoading,
    dataTS,
  ) => {
    const input = find(inputs, (input) => input.name === inputName);
    if (!input) {
      return;
    }

    // "inputValues" as bound in the closure represent the values that kicked off this query. But those
    // might have changed while the query was running. We'll look at the ref (which gets updated with
    // the latest values) to get the most recent values for comparison.
    const currentNestedInputValues = {};
    if (input.nested_parameters) {
      Object.keys(input.nested_parameters).forEach((nestedInputName) => {
        currentNestedInputValues[nestedInputName] = currentInputValuesRef.current[nestedInputName]?.value;
      });
    }

    const updatedInputValue = {
      isLoading: isStillLoading,
      dataTS,
    };

    // Checking the nested values ensures that if a user changes a nested input value before
    // the first query completes, we don't show values for the no-longer-valid query after the results finally come in.
    if (isEqual(nestedInputValues, currentNestedInputValues)) {
      updatedInputValue.options = options;
      updatedInputValue.mappedOptions = mappedOptions;
    }
    onInputValueChange({ [inputName]: updatedInputValue });
  };

  const handleInputChange = (inputName, updatedInputValue) => {
    onInputValueChange({ [inputName]: updatedInputValue });
  };

  const tabs = [
    {
      label: 'Inputs',
      selected: activeTab === TABS.INPUTS,
      onClick: (e) => handleTabClick(e, TABS.INPUTS),
    },
    {
      label: 'CSV Upload',
      selected: activeTab === TABS.UPLOAD,
      onClick: (e) => handleTabClick(e, TABS.UPLOAD),
    },
  ];

  return (
    <React.Fragment>
      {!isReadOnly && userContext.user?.enterprise?.enterprise_settings?.csv_bulk_enabled && !test && (
        <TabHeader tabs={tabs} />
      )}
      {activeTab === TABS.UPLOAD ? (
        <CsvBulkUploadForm template={template} isReadOnly={isReadOnly} />
      ) : (
        <>
          {dataSourcesWithAuthToken?.map((dataSource) => (
            <DataSourceAuthTokenInput
              key={dataSource.id}
              dataSource={dataSource}
              allDynamicContent={allDynamicContent}
              tokenError={tokenError}
              tokenSuccess={tokenSuccess}
              tokenLoading={tokenLoading}
              inputValues={inputValues}
              onTestAccess={onTestAccess}
              authTokenByDynamicContent={authTokenByDynamicContent}
              setAuthTokenByDynamicContent={setAuthTokenByDynamicContent}
            />
          ))}
          {utils.hasMatikUserInputs(inputs) && (
            <MatikUserInputField
              inputs={inputs}
              inputValues={inputValues}
              onInputOptionsChange={handleInputOptionsFromQuery}
              userContext={userContext}
              setUserBasedInputsIsLoading={setUserBasedInputsIsLoading}
              isPresentationView={isPresentationView}
            />
          )}
          {inputs.map((input, idx) => (
            <InputField
              key={input.id}
              input={input}
              inputIndex={idx}
              inputValues={inputValues}
              inputs={inputs}
              template={template}
              attachedTemplate={attachedTemplate}
              isProducer={isProducer}
              isTestSlide={isTestSlide}
              currentBulkInputName={currentBulkInputName}
              isReadOnly={isReadOnly}
              userContext={userContext}
              onChange={handleInputChange}
              onInputOptionsChange={handleInputOptionsFromQuery}
              allowBulk={allowBulk}
              onBulkInputToggle={onBulkInputToggle}
              isPresentationView={isPresentationView}
              slideIdsByInputName={slideIdsByInputName}
            />
          ))}
        </>
      )}
    </React.Fragment>
  );
}

FormFromInputs.propTypes = {
  allowBulk: PropTypes.bool,
  currentBulkInputName: PropTypes.string,
  inputs: PropTypes.array,
  inputValues: PropTypes.object,
  isReadOnly: PropTypes.bool,
  isPresentationView: PropTypes.bool,
  onBulkInputToggle: PropTypes.func,
  slideIdsByInputName: PropTypes.object,
  onInputValueChange: PropTypes.func,
  userContext: PropTypes.object,
  template: PropTypes.object,
  attachedTemplate: PropTypes.object,
  dataSourcesWithAuthToken: PropTypes.array,
  allDynamicContent: PropTypes.object,
  tokenError: PropTypes.any,
  tokenSuccess: PropTypes.any,
  tokenLoading: PropTypes.bool,
  authTokenByDynamicContent: PropTypes.object,
  setAuthTokenByDynamicContent: PropTypes.func,
  onTestAccess: PropTypes.func,
  isProducer: PropTypes.bool,
  inModal: PropTypes.bool,
  isTestSlide: PropTypes.bool,
  activeTab: PropTypes.string,
  handleTabClick: PropTypes.func,
  test: PropTypes.bool,
  setUserBasedInputsIsLoading: PropTypes.func,
};

export default withUserContext(FormFromInputs);

const LoopingIcon = ({ input, bulkInputName, loopedInfo }) => {
  const dependsOnBulk = inputsLib.inputIsDependentOnBulkInput(input, bulkInputName);
  return (
    <div
      data-tooltip-place="right"
      data-tooltip-id="matik-tooltip"
      data-tooltip-content={
        dependsOnBulk
          ? 'Each presentation in the bulk job will loop through all matching values of this input'
          : 'This input will be looped on, with customized content being generated for each of its provided values.'
      }
    >
      <IconPill color={loopedInfo?.color} size="m" theme="circle" iconName="loop" />
    </div>
  );
};
LoopingIcon.displayName = 'FormFromInputs.LoopingIcon';
LoopingIcon.propTypes = {
  input: PropTypes.object,
  bulkInputName: PropTypes.string,
  loopedInfo: PropTypes.object,
};
const getLoopedInputInfo = (input, template, attachedTemplate) => {
  const loops = (template?.slide_loops || []).concat(attachedTemplate?.slide_loops || []);
  return getLoopedInfo(input, loops);
};

function InputField({
  input,
  inputValues,
  inputs,
  inputIndex,
  template,
  attachedTemplate,
  isProducer,
  isTestSlide,
  currentBulkInputName,
  isReadOnly,
  userContext,
  onChange,
  onInputOptionsChange,
  allowBulk,
  onBulkInputToggle,
  isPresentationView,
  slideIdsByInputName,
}) {
  const context = useContext(EndUserCreateContext);

  const handleMouseEnter = (inputName) => {
    context.highlightSlideNums(slideIdsByInputName[inputName]);
  };

  const handleMouseLeave = () => {
    context.highlightSlideNums([]);
  };

  const beforeQuery = (inputName) => {
    const inputValue = inputValues[inputName];
    if (inputValue) {
      onChange(inputName, { isLoading: true });
    }
  };

  const fallback = (inputsStillRequired) => `Depends on ${utils.convertArrayToEnglish(inputsStillRequired)}...`;

  const queryError = (err, inputName) => {
    const inputValue = inputValues[inputName];
    if (inputValue) {
      onChange(inputName, { fetch_error: err, isLoading: false });
    }
  };

  const handleChange = (inputName, updatedInputValues) => {
    onChange(inputName, updatedInputValues[inputName]);
  };

  const inputValue = inputValues[input.name];

  if (!inputValue) {
    return null;
  }
  let inputColor = null;
  let errorText = null;
  if (inputValue.error) {
    inputColor = 'danger';
    errorText = <Form.Help color="danger">{inputValue.error}</Form.Help>;
  }
  const nameLabel = input.display_name ? input.display_name : input.name;

  const inputNamesCheck = {};
  inputs.forEach((input) => (inputNamesCheck[input.name] = true));

  const loopedInfo = getLoopedInputInfo(input, template, attachedTemplate);

  const isInputMapped =
    input.input_mapping &&
    isProducer &&
    !isEmpty(input.input_mapping) &&
    !isEqual(Object.keys(input.input_mapping)[0], Object.values(input.input_mapping)[0]);

  const canInputBulk =
    allowBulk && inputsLib.inputSupportsBulk(input, inputNamesCheck) && !loopedInfo && !isProducer && !isTestSlide;

  const isInputBulk = canInputBulk && currentBulkInputName === input.name;

  let description;
  if (input.description) {
    if (utils.isValidJSON(input.description)) {
      const descJSON = utils.removeCopiedFontStylesFromWYSIWYGOutput(JSON.parse(input.description));
      description = parse(draftToHtml(descJSON));
    } else {
      description = input.description;
    }
  } else {
    description = 'No description';
  }

  let inputField = '';

  if (isInputBulk || loopedInfo) {
    inputField = inputsLib.getBulkFieldFromInputType(
      input,
      inputValues,
      inputColor,
      handleChange,
      onInputOptionsChange,
      queryError,
      fallback,
      beforeQuery,
      isReadOnly,
      currentBulkInputName,
    );
  } else {
    inputField = inputsLib.getFieldFromInputType(
      input,
      inputValues,
      inputColor,
      handleChange,
      onInputOptionsChange,
      queryError,
      fallback,
      beforeQuery,
      isReadOnly,
      true, // allowRelativeDates
      currentBulkInputName,
    );
  }

  let mappedValue;
  if (isInputMapped && inputField.props?.inputValues?.[input.name]?.['mappedOptions']) {
    if (Array.isArray(inputField.props.inputValues[input.name]['value'])) {
      mappedValue = inputField.props.inputValues[input.name]['value']
        .map((val) => inputField.props.inputValues[input.name]['mappedOptions'][val])
        .join(', ');
    } else {
      mappedValue =
        inputField.props.inputValues[input.name]['mappedOptions'][inputField.props.inputValues[input.name]['value']];
    }
  }

  const hideFormMatikUserInput = !isPresentationView && input.source_type === Constants.InputSources.MATIK_USER;
  const isLastInput = inputIndex === inputs.length - 1;

  return (
    <div key={input.name} className={`${isLastInput ? 'mb-6' : ''} pb-3 ${hideFormMatikUserInput ? 'hidden' : ''}`}>
      <Form.Field
        className="input-field"
        onMouseOver={() => handleMouseEnter(input.name)}
        onMouseLeave={() => handleMouseLeave()}
      >
        <div className="is-flex is-vertical-centered gap-2">
          {loopedInfo && <LoopingIcon input={input} bulkInputName={currentBulkInputName} loopedInfo={loopedInfo} />}
          <div className="text-overflow-ellipsis">
            <Form.Label onClick={(e) => e.preventDefault()}>
              {isInputMapped && <InputMappingIcon className="icon mrs" />}
              <span className="mrs">{nameLabel}</span>{' '}
              {!isReadOnly && !loopedInfo && (
                <BulkButton
                  isInputBulk={isInputBulk}
                  input={input}
                  currentBulkInputName={currentBulkInputName}
                  template={template}
                  userContext={userContext}
                  allowBulk={canInputBulk}
                  onBulkInputToggle={onBulkInputToggle}
                />
              )}
              <QueryLoading
                isLoading={inputValue.isLoading}
                dataTS={inputValue.dataTS}
                onSyncButtonClick={() =>
                  inputsLib.loadOptionsFromQuery(
                    input,
                    inputValues,
                    onInputOptionsChange,
                    queryError,
                    fallback,
                    beforeQuery,
                    null,
                    currentBulkInputName,
                  )
                }
              />
            </Form.Label>
            <Form.Help className="whitespace-normal" renderAs="div">
              {description}
            </Form.Help>
          </div>
        </div>
        <Form.Control>{inputField}</Form.Control>
        {isInputMapped && mappedValue && (
          <Form.Help className="input-mapping">
            {inputField.props && inputField.props.input && inputField.props.input.input_mapping
              ? `Maps to ${Object.values(inputField.props.input.input_mapping)[0]}: ${mappedValue}`
              : ''}
          </Form.Help>
        )}
        {errorText}
      </Form.Field>
    </div>
  );
}
InputField.displayName = 'FormFromInputs.InputField';
InputField.propTypes = {
  allowBulk: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    display_name: PropTypes.string,
    input_mapping: PropTypes.object,
    source_type: PropTypes.string,
    description: PropTypes.string,
  }),
  inputValues: PropTypes.object,
  inputs: PropTypes.array,
  inputIndex: PropTypes.number,
  userContext: PropTypes.object,
  template: PropTypes.object,
  attachedTemplate: PropTypes.object,
  isProducer: PropTypes.bool,
  isTestSlide: PropTypes.bool,
  currentBulkInputName: PropTypes.string,
  isReadOnly: PropTypes.bool,
  isPresentationView: PropTypes.bool,
  onBulkInputToggle: PropTypes.func,
  slideIdsByInputName: PropTypes.object,
  onChange: PropTypes.func,
  onInputOptionsChange: PropTypes.func,
};

function BulkButton({ input, isInputBulk, currentBulkInputName, template, userContext, allowBulk, onBulkInputToggle }) {
  if (!template || !allowBulk) {
    return null;
  }

  const isBulkGenerationEnabledForEnterprise =
    userContext?.user?.enterprise?.enterprise_settings?.bulk_generation_enabled;
  const isBulkGenerationEnabledForTier =
    userContext?.user?.enterprise && !teams.isTeamsUser(userContext.user.enterprise.plan_id);

  // Show the bulk icon when bulk-gen is enabled on the enterprise
  // OR they aren't subscribed to it and we want the icon for upselling
  if (!isBulkGenerationEnabledForEnterprise && isBulkGenerationEnabledForTier) {
    return null;
  }

  const handleBulkInputToggle = (e) => {
    e.preventDefault();
    if (isBulkGenerationEnabledForEnterprise && isBulkGenerationEnabledForTier) {
      onBulkInputToggle(input.name, !isInputBulk);
    } else {
      const planName = Constants.MATIK_TIERS[userContext.user.enterprise.plan_id].display_name;
      const noBulkHtml = ReactDOMServer.renderToStaticMarkup(
        <UpsellGenericModal
          featureHeader={`Generate Bulk Presentations with Matik ${Constants.MATIK_TIERS.matik_enterprise.display_name}`}
          featureDescription={`Bulk Generation is not available for the ${planName} plan. Please upgrade to generate bulk presentations.`}
        />,
      );
      API.track('enterprise_upsell_shown', { from: 'no_bulk_generation' });
      MProductUpsell(`${noBulkHtml}`, false, (result) => {
        if (!result.dismiss) {
          API.track('discover_matik_enterprise_click', { from: 'no_bulk_generation' });
          const demoUrl = teams.buildRequestDemoUrl(
            userContext.user,
            'bulk_generation',
            userContext.user.enterprise.trial_days_remaining,
          );
          window.open(demoUrl, '_blank');
        }
      });
    }
  };

  if (template?.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL) {
    if (!isInputBulk) {
      return null;
    }
    return (
      <Button type="button" category="tertiary" size="small" disabled={true} onClick={null}>
        <BulkLogo data-tooltip-content="This is the bulk input for this email" data-tooltip-id="matik-tooltip" />
      </Button>
    );
  } else {
    let tip = 'Click to bulk generate based on this input';
    if (isInputBulk) {
      tip = 'Click to return this to single generation';
    } else if (currentBulkInputName) {
      tip = 'Bulk presentations are being generated from another input';
    }

    const color = isInputBulk ? 'has-text-success' : '';
    const disableToggle = currentBulkInputName && !isInputBulk;

    return (
      <div data-tooltip-id="matik-tooltip" data-tooltip-content={tip} data-tooltip-place="right">
        <Button
          type="button"
          category="tertiary"
          size="small"
          status={disableToggle ? 'disabled' : 'default'}
          onClick={handleBulkInputToggle}
        >
          <BulkLogo className={color} />
        </Button>
      </div>
    );
  }
}
BulkButton.displayName = 'FormFromInputs.BulkButton';
BulkButton.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  isInputBulk: PropTypes.bool,
  currentBulkInputName: PropTypes.string,
  template: PropTypes.object,
  userContext: PropTypes.object,
  allowBulk: PropTypes.bool,
  onBulkInputToggle: PropTypes.func,
};

function DataSourceAuthTokenInput({
  dataSource,
  allDynamicContent,
  tokenError,
  tokenSuccess,
  tokenLoading,
  inputValues,
  onTestAccess,
  authTokenByDynamicContent,
  setAuthTokenByDynamicContent,
}) {
  const handleTestAccess = (e, dataSource, dynamicContent) => {
    e.preventDefault();
    const apiInputValues = {};
    for (let key in inputValues) {
      if (key in dynamicContent.parameters) {
        if (inputValues[key].value) {
          apiInputValues[key] = inputValues[key];
        }
      }
    }
    onTestAccess(e, dataSource, dynamicContent, apiInputValues, authTokenByDynamicContent[dynamicContent.name]);
  };

  const onAuthTokenChange = (e, dynamicContent) => {
    setAuthTokenByDynamicContent(dynamicContent, e.target.value);
  };

  const dynamicContent = Object.values(allDynamicContent || {}).filter(
    (dc) => dc.query_obj?.data_source?.id === dataSource.id,
  );
  return (
    <AuthTokenInput
      key={`${dynamicContent}_${dataSource}`}
      dynamicContent={dynamicContent}
      dataSource={dataSource}
      tokenError={tokenError}
      tokenSuccess={tokenSuccess}
      tokenLoading={tokenLoading}
      onAuthTokenChange={onAuthTokenChange}
      onTestAccess={handleTestAccess}
    />
  );
}
DataSourceAuthTokenInput.displayName = 'FormFromInputs.DataSourceAuthTokenInput';
DataSourceAuthTokenInput.propTypes = {
  dataSource: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  allDynamicContent: PropTypes.object,
  tokenError: PropTypes.any,
  tokenSuccess: PropTypes.any,
  tokenLoading: PropTypes.bool,
  inputValues: PropTypes.object,
  onTestAccess: PropTypes.func,
  authTokenByDynamicContent: PropTypes.object,
  setAuthTokenByDynamicContent: PropTypes.func,
};
