import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { UserContext } from '../../UserContext';
import spreadsheetUtils from '../../../lib/spreadsheetUtils';
import utils from '../../../lib/utils';
import WithSpreadSheetContext from './WithSpreadSheetContext';
import SpreadSheetFooter from './SpreadSheetFooter';
import SmallLoader from '../../shared/SmallLoader';
import warning_icon from '../../../images/warning.svg';
import MemoSpreadsheet from './MemoSpreadsheet';
import API from '../../../lib/api';
import { MInsufficientPerms } from '../Alerts';
import { findIndex } from 'lodash';
import Constants from '../../Constants';

class SpreadSheetDisplay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rowData: null,
      isLoading: true,
      loadingError: false,
      loadingErrorMessage: 'Unable to load sheet data',
    };
  }

  componentDidMount() {
    if (this.props.SpreadSheetContext.dataSource.type === Constants.DATA_SOURCE_TYPES.google_sheet) {
      this.context.refreshOauthToken(this.checkGoogleConnection);
    } else if (this.props.SpreadSheetContext.dataSource.type === Constants.DATA_SOURCE_TYPES.excel) {
      this.fetchExcelContents();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.SpreadSheetContext.dataSource.type === Constants.DATA_SOURCE_TYPES.google_sheet) {
      if (
        prevProps.SpreadSheetContext.cellSelection !== this.props.SpreadSheetContext.cellSelection &&
        this.props.SpreadSheetContext.cellSelection
      ) {
        this.setSheetFromContext();
      }
      if (prevProps.SpreadSheetContext.dataSource.id !== this.props.SpreadSheetContext.dataSource.id) {
        this.checkGoogleConnection();
      }
    } else if (this.props.SpreadSheetContext.dataSource.type === Constants.DATA_SOURCE_TYPES.excel) {
      if (prevProps.SpreadSheetContext.dataSource.id !== this.props.SpreadSheetContext.dataSource.id) {
        this.fetchExcelContents();
      }
    }
  }

  render() {
    let spreadsheet = '';
    if (this.state.isLoading) {
      spreadsheet = (
        <div className="sheet-contents-loader ptl">
          <SmallLoader />
        </div>
      );
    } else if (this.state.loadingError) {
      spreadsheet = (
        <div className="spreadsheet-loading-error ptl">
          <div className="prs">
            <img src={warning_icon} width="30px" alt="Unable to load" />
          </div>
          <span className="spreadsheet-error-message">{this.state.loadingErrorMessage}</span>
        </div>
      );
    } else {
      spreadsheet = <MemoSpreadsheet rowData={this.state.rowData} onCellSelect={this.onCellSelect} />;
    }

    let spreadsheetId = '';
    const dataSourceHost = this.props.SpreadSheetContext.dataSource && this.props.SpreadSheetContext.dataSource.host;
    if (dataSourceHost) {
      spreadsheetId = JSON.parse(dataSourceHost)['id'];
    }
    return (
      <div>
        {spreadsheet}
        <SpreadSheetFooter
          selectSheet={this.selectSheet}
          selectPreviousSheet={this.previousSheet}
          selectNextSheet={this.nextSheet}
          documentId={spreadsheetId}
        />
      </div>
    );
  }

  checkGoogleConnection = () => {
    if (this.props.SpreadSheetContext.dataSource.type === Constants.DATA_SOURCE_TYPES.google_sheet) {
      const googleIntegration = utils.googleIntegration(this.context.user);
      if (!!googleIntegration === false) {
        this.connectGoogle();
      } else if (googleIntegration && !googleIntegration.has_necessary_scopes) {
        this.reconnectGoogle();
      } else {
        this.fetchGoogleSheetContents();
      }
    } else if (this.props.SpreadSheetContext.dataSource.type === Constants.DATA_SOURCE_TYPES.excel) {
      this.fetchExcelContents();
    }
  };

  fetchGoogleSheetContents = () => {
    const dataSourceHost = this.props.SpreadSheetContext.dataSource && this.props.SpreadSheetContext.dataSource.host;
    if (!dataSourceHost) {
      this.setState({ isLoading: false, loadingError: true });
      return;
    }
    API.get(
      `/data_sources/${this.props.SpreadSheetContext.dataSource.id}/google_sheet/`,
      (response) => {
        this.props.SpreadSheetContext.setSheets(response.data.sheets.map((sheet) => sheet.properties));
        this.fetchRows();
      },
      this.handleDocLoadError,
    );
  };

  fetchExcelContents = () => {
    let dataSourceHost = this.props.SpreadSheetContext.dataSource && this.props.SpreadSheetContext.dataSource.host;
    if (!dataSourceHost) {
      this.setState({ isLoading: false, loadingError: true });
      return;
    }
    dataSourceHost = JSON.parse(dataSourceHost);
    API.get(
      `/data_sources/${this.props.SpreadSheetContext.dataSource.id}/excel/`,
      (response) => {
        this.props.SpreadSheetContext.setSheets(
          response.data.sheets.map((sheet, idx) => ({
            title: sheet,
            index: idx,
          })),
        );
        this.fetchRows();
      },
      this.handleDocLoadError,
    );
  };

  fetchRows = (sheetIndex = this.props.SpreadSheetContext.worksheetIndex) => {
    this.setState({ isLoading: true, loadingError: false });
    if (this.props.SpreadSheetContext.sheets.length < sheetIndex) {
      sheetIndex = 0;
    }
    if (this.props.SpreadSheetContext.sheets) {
      this.setState({ isLoading: true, loadingError: false });
    }
    const sheet = this.props.SpreadSheetContext.sheets[sheetIndex]?.title;
    const sheet_type =
      this.props.SpreadSheetContext.dataSource.type === Constants.DATA_SOURCE_TYPES.google_sheet
        ? 'google_sheet'
        : 'excel_sheet';
    API.get(
      `/data_sources/${this.props.SpreadSheetContext.dataSource.id}/${sheet_type}/${sheet}/`,
      (response) => {
        let rowValues = response.data.rows;
        if (this.props.SpreadSheetContext.dataSource.type === Constants.DATA_SOURCE_TYPES.google_sheet) {
          rowValues = rowValues.values ? rowValues.values : [];
        }

        let parsedRowData = spreadsheetUtils.formatRowData(rowValues);
        this.setState({ rowData: parsedRowData, isLoading: false, loadingError: false });
      },
      this.handleDocLoadError,
    );
  };

  setSheetFromContext = () => {
    if (this.props.SpreadSheetContext.sheets) {
      let sheetIndex = 0;
      if (this.props.SpreadSheetContext.cellSelection && this.props.SpreadSheetContext.cellSelection.sheet) {
        sheetIndex = findIndex(
          this.props.SpreadSheetContext.sheets,
          (s) => s.title === this.props.SpreadSheetContext.cellSelection.sheet,
        );
      }
      if (sheetIndex > -1 && sheetIndex !== this.props.SpreadSheetContext.worksheetIndex) {
        this.props.SpreadSheetContext.setCurrentWorksheetIndex(sheetIndex);
        this.fetchRows(sheetIndex);
      }
    }
  };

  selectSheet = (sheetIndex) => {
    if (sheetIndex >= 0 && sheetIndex < this.props.SpreadSheetContext.sheets.length) {
      this.props.SpreadSheetContext.setCurrentWorksheetIndex(sheetIndex);
      this.fetchRows(sheetIndex);
    }
  };

  previousSheet = (e) => {
    e.preventDefault();
    const sheetIndex = this.props.SpreadSheetContext.worksheetIndex;
    let element = document.getElementById('workbook-' + (sheetIndex - 1));
    if (element) {
      element.scrollIntoView();
    }
    if (sheetIndex > 0) {
      this.props.SpreadSheetContext.setCurrentWorksheetIndex(sheetIndex - 1);
      this.fetchRows();
    }
  };

  nextSheet = (e) => {
    e.preventDefault();
    const sheetIndex = this.props.SpreadSheetContext.worksheetIndex;
    let element = document.getElementById('workbook-' + (sheetIndex + 1));
    if (element) {
      element.scrollIntoView();
    }
    if (sheetIndex < this.props.SpreadSheetContext.sheets.length - 1) {
      this.props.SpreadSheetContext.setCurrentWorksheetIndex(sheetIndex + 1);
      this.fetchRows();
    }
  };

  onCellSelect = (selected) => {
    if (selected.length === 0) {
      return;
    }
    const worksheetTitle =
      this.props.SpreadSheetContext.sheets &&
      this.props.SpreadSheetContext.worksheetIndex < this.props.SpreadSheetContext.sheets.length
        ? this.props.SpreadSheetContext.sheets[this.props.SpreadSheetContext.worksheetIndex].title
        : '';
    const a1Notation = spreadsheetUtils.cellArrayToA1Notation(selected, worksheetTitle, this.state.rowData.length);
    this.props.SpreadSheetContext.onCellUpdate(a1Notation);
    API.track('google_sheet_cell_select');
  };

  showPermsAlert = () => {
    const title = 'Grant Google Drive permissions to Matik';
    const message =
      'In order to display your Google Sheet, you will need to select allow in the authentication window.';
    MInsufficientPerms(title, message, this.reconnectGoogle);
  };

  connectGoogle = () => {
    utils.connectGoogle(this.context.user, this.context.updateUser, this.fetchGoogleSheetContents, this.showPermsAlert);
  };

  handleDocLoadError = (err) => {
    this.setState({ isLoading: false, loadingError: true, loadingErrorMessage: err.message });
  };

  reconnectGoogle = () => {
    utils.reconnectGoogle(
      this.context.user,
      this.context.updateUser,
      this.fetchGoogleSheetContents,
      this.showPermsAlert,
    );
  };
}

SpreadSheetDisplay.propTypes = {
  SpreadSheetContext: PropTypes.object,
};

SpreadSheetDisplay.contextType = UserContext;

export default WithSpreadSheetContext(SpreadSheetDisplay);
