import React, { useState, useRef, createContext } from 'react';
import PropTypes from 'prop-types';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import { useParams } from 'react-router-dom';
import Constants from 'components/Constants';
import ReactTablePagination from './ReactTablePagination';
import LoadingOverlay from './LoadingOverlay';
import Icon from 'components/lib/Icon';
import utils from 'lib/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ReactTableActionDropdown from './ReactTableActionDropdown';
import { Cell, CellDescription } from 'components/shared/Cell';

export const ExpandedContext = createContext({
  expandedState: false,
});

const FullPageList = ({
  entitiesToRender,
  columns,
  hideHeader,
  hideTopPagination,
  hideAllPagination,
  pagination,
  fetchItems,
  scrollInElement,
  onHeaderClick,
  isSearchable,
  emailEditorType,
  isSidebar,
  loading,
  hasStickyColumns,
  onRowClick: propsOnRowClick,
}) => {
  const params = useParams();
  const flags = useFlags();

  // Dc Full Page List
  const isDynamicContentFullPageList =
    params.tab === Constants.Tabs.DYNAMIC_CONTENT && !isSidebar && hasStickyColumns && flags.enableDataDictionary;
  const headerRefs = useRef({});
  const cellRefs = useRef({});
  const [dropdownState, setDropdownState] = useState(null);
  const [cellDropdownState, setCellDropdownState] = useState(null);
  const [expandedState, setExpandedState] = useState(false);
  const [sortedState, setSortedState] = useState({ headerId: null, sort: null });

  const onSetDropdownState = (headerId) => {
    if (dropdownState === headerId) {
      setDropdownState(null);
    } else {
      setDropdownState(headerId);
    }
  };

  const onSetCellDropdownState = (cellId) => {
    if (cellDropdownState === cellId) {
      setCellDropdownState(null);
    } else {
      setCellDropdownState(cellId);
    }
  };

  const onRowClick = (e, rowInfo) => {
    if (isSearchable) {
      e.preventDefault();
    } else {
      e.persist();
    }
    if (e.target.tagName.toLowerCase() === 'label' || e.target.tagName.toLowerCase() === 'input') {
      return;
    }
    if (e.target.classList && e.target.classList.contains('presentation-list-menu-icon')) {
      return;
    }
    if (
      e.target.className &&
      typeof e.target.className === 'string' &&
      e.target.className.split(' ').includes('checkbox-cell')
    ) {
      return;
    }
    if (propsOnRowClick) {
      if (rowInfo.original?.flow_type) {
        propsOnRowClick(rowInfo.original.flow_type, rowInfo.original.id);
      } else {
        propsOnRowClick(rowInfo.original.id);
      }
    }
    if (onHeaderClick) {
      onHeaderClick(e, rowInfo.original.id);
    }
  };

  const onSortedChangeDCFullPageList = (columnId, sortedString) => {
    let newSort = null;
    if (!pagination.sort || pagination.sort[0] !== columnId) {
      // New column, set to asc
      newSort = [columnId, sortedString];
      setSortedState({ headerId: columnId, sort: sortedString });
    } else if (pagination.sort[1] === sortedString) {
      // Current column and same direction, remove sort
      newSort = null;
      setSortedState({ headerId: null, sort: null });
    } else {
      // Current column but different direction, set to new direction
      newSort = [columnId, sortedString];
      setSortedState({ headerId: columnId, sort: sortedString });
    }
    fetchItems(pagination.currentPage, newSort);
  };

  const onSortedChange = (columnId) => {
    let newSort = null;
    if (!pagination.sort || pagination.sort[0] !== columnId) {
      // New column, set to asc
      newSort = [columnId, 'asc'];
    } else if (pagination.sort[1] === 'asc') {
      // Current column is asc, change to desc
      newSort = [columnId, 'desc'];
    } else {
      // Current column is desc, remove sort
      newSort = null;
    }
    fetchItems(pagination.currentPage, newSort);
  };

  const isSorted = (columnId) => {
    return pagination?.sort && pagination.sort[0] === columnId;
  };

  const getSortDirection = (columnId) => {
    if (isSorted(columnId)) {
      return pagination.sort[1];
    }
    return null;
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(`{{${text}}}`);
      utils.notify('Copied to clipboard');
    } catch (err) {
      utils.notify('Failed to copy to clipboard');
    }
  };

  const shouldDisplayCopyButton = (row) => {
    const dynamicContentType = row.original.dynamic_content_type;

    const isText = dynamicContentType === Constants.DynamicContentTypes.TEXT;

    return !emailEditorType || ((emailEditorType === 'code' || emailEditorType === 'visual') && isText);
  };

  const renderCopyButtonCell = (row) => {
    if (shouldDisplayCopyButton(row)) {
      return (
        <button
          className="icon-button"
          onClick={(e) => {
            e.stopPropagation();
            copyToClipboard(row.original.name, row.original.dynamic_content_type);
          }}
        >
          <span className="flex items-center">
            <Icon name="copy" size={20} theme="regular" />
          </span>
        </button>
      );
    }
    return null;
  };

  const columnHelper = createColumnHelper();

  if (emailEditorType === 'code' || emailEditorType === 'visual') {
    columns = [
      ...columns,
      columnHelper.display({
        id: 'copy',
        header: '',
        cell: ({ row }) => renderCopyButtonCell(row),
        meta: {
          width: '50px',
        },
        enableSorting: false,
        enableColumnFilter: false,
      }),
    ];
  }

  const getCommonPinningStyles = (column) => {
    const isPinned = column.getIsPinned();
    const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn('left');

    const styles = {
      position: isPinned ? 'sticky' : 'relative',
      left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    };

    if (hasStickyColumns) {
      styles.zIndex = isPinned ? 2 : 0;
      styles.width = column.getSize();
      styles.borderRight = isLastLeftPinnedColumn ? '1px solid #e5e5e5' : undefined;
    }

    return styles;
  };

  const table = useReactTable({
    data: entitiesToRender || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: 'onChange',
    sortDescFirst: true,
    enableColumnPinning: hasStickyColumns,
    initialState: {
      columnPinning: {
        left: hasStickyColumns ? ['checkbox', 'dynamic_content_method_icon', 'dynamic_content_type_icon', 'name'] : [],
        right: [],
      },
    },
  });

  const cn = (...classes) => classes.filter(Boolean).join(' ');
  const getClassNames = () => {
    const prefix = isDynamicContentFullPageList ? 'dc-' : '';

    return {
      tableContainer: cn('overflow-x-auto', isDynamicContentFullPageList && 'h-full bg-grey-100'),
      table: cn(`${prefix}full-page-table`, scrollInElement && 'isScrollable', !hasStickyColumns && 'w-full'),
      headerRow: cn(
        `${prefix}full-page-table-row-header`,
        (isSidebar || params.tab === Constants.Tabs.FLOWS) && 'smallerFont',
      ),
      bodyRow: cn(
        `${prefix}full-page-table-row-body`,
        (isSidebar || params.tab === Constants.Tabs.FLOWS) && 'smallerFont',
        hideHeader && !hideAllPagination && 'show-top-border',
      ),
      thead: cn(isSidebar && 'full-page-table-thead', isDynamicContentFullPageList && 'sticky top-0 z-20'),
      pagination: cn(!isSidebar && 'flex justify-end'),
      headerCell: cn(`${prefix}full-page-table-cell`, isDynamicContentFullPageList && 'header'),
      bodyCell: cn(`${prefix}full-page-table-cell`),
    };
  };
  const classes = getClassNames();

  return (
    <ExpandedContext.Provider value={{ expandedState }}>
      <LoadingOverlay loading={loading} />
      {!(hideTopPagination || hideAllPagination) &&
        (isDynamicContentFullPageList ? true : (pagination?.numberOfPages ?? 0) > 1) && (
          <div className={classes.pagination}>
            <ReactTablePagination
              page={pagination.currentPage}
              pages={pagination.numberOfPages}
              onPageChange={fetchItems}
            />
          </div>
        )}
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          {!hideHeader && (
            <thead className={classes.thead}>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr className={classes.headerRow} key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      className={classes.headerCell}
                      key={header.id}
                      onClick={() =>
                        isDynamicContentFullPageList ? onSetDropdownState(header.id) : onSortedChange(header.id)
                      }
                      ref={(element) => (headerRefs.current[header.id] = element)}
                      style={{
                        maxWidth: header.column.columnDef.meta?.width,
                        ...getCommonPinningStyles(header.column),
                      }}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {isSorted(header.id) && (
                        <span className="ml-1 flex items-center">
                          <Icon
                            name={getSortDirection(header.id) === 'desc' ? 'chevron_down' : 'chevron_up'}
                            size={16}
                            theme="regular"
                          />
                        </span>
                      )}
                      {isDynamicContentFullPageList && dropdownState === header.id && (
                        <ReactTableActionDropdown
                          onSortFunc={onSortedChangeDCFullPageList}
                          sortedState={sortedState}
                          expandedState={expandedState}
                          setExpandedStateFunc={setExpandedState}
                          headerId={header.id}
                          headerRef={headerRefs.current[header.id]}
                        />
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
          )}
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                className={classes.bodyRow}
                key={row.id}
                onClick={(e) => {
                  if (
                    e.target.classList &&
                    !e.target.classList.contains('dc-description-cell') &&
                    !e.target.children?.[0]?.classList?.contains('dc-description-cell')
                  ) {
                    onRowClick(e, row);
                  }
                }}
              >
                {row.getVisibleCells().map((cell) => {
                  const cellStyles = {
                    ...(cell.column.columnDef.meta?.tdStyles || {}),
                    justifyContent: cell.column.columnDef.meta?.justifyContent,
                    maxWidth: cell.column.columnDef.meta?.width,
                    ...getCommonPinningStyles(cell.column),
                  };
                  return (
                    <>
                      {cell.column.id === 'description' && isDynamicContentFullPageList ? (
                        <CellDescription
                          cell={cell}
                          cellDropdownId={cellDropdownState}
                          rowData={row.original}
                          onClose={setCellDropdownState}
                          cellClass={classes.bodyCell}
                          key={cell.id}
                          onCellClick={onSetCellDropdownState}
                          cellRefs={cellRefs}
                          cellStyle={cellStyles}
                        />
                      ) : (
                        <Cell
                          cell={cell}
                          cellClass={classes.bodyCell}
                          key={cell.id}
                          cellRefs={cellRefs}
                          cellStyle={cellStyles}
                        />
                      )}
                    </>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {!hideAllPagination && !isDynamicContentFullPageList && (pagination?.numberOfPages ?? 0) > 1 && (
        <div className={classes.pagination}>
          <ReactTablePagination
            page={pagination.currentPage}
            pages={pagination.numberOfPages}
            onPageChange={fetchItems}
          />
        </div>
      )}
    </ExpandedContext.Provider>
  );
};

FullPageList.propTypes = {
  entitiesToRender: PropTypes.array,
  columns: PropTypes.array,
  fetchItems: PropTypes.func,
  hideHeader: PropTypes.bool,
  hideTopPagination: PropTypes.bool,
  hideAllPagination: PropTypes.bool,
  loading: PropTypes.bool,
  onRowClick: PropTypes.func,
  pagination: PropTypes.object,
  scrollInElement: PropTypes.bool,
  onHeaderClick: PropTypes.func,
  isSearchable: PropTypes.bool,
  emailEditorType: PropTypes.string,
  isSidebar: PropTypes.bool,
  hasStickyColumns: PropTypes.bool,
};

export default FullPageList;
