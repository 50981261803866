import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import { useLocation } from 'react-router-dom';
import Button from 'components/lib/Button';
import { Select } from 'components/shared/FormSelect';

/**
 * A form field component that manages the display and editing of BigQuery
 * service account credentials. The service account JSON is only displayed
 * to admins when creating a new data source or when replacing the existing
 * service account JSON.
 */
function ServiceAccountInfo({ auth, isOAuth, onAuthChange }) {
  const [isReplacingServiceAccountInfo, setIsReplacingServiceAccountInfo] = useState(false);
  const [existingServiceAccountInfo, setExistingServiceAccountInfo] = useState(auth.service_account_info);
  const [newServiceAccountInfo, setNewServiceAccountInfo] = useState(null);

  const location = useLocation();
  const isNewDataSource = location.pathname === '/data_sources/new';

  // When the form is submitted, reset the form state.
  useEffect(() => {
    const dataSourceForm = document.querySelector('form');

    const handleFormSubmit = () => {
      if (isReplacingServiceAccountInfo) {
        setExistingServiceAccountInfo(auth.service_account_info);
        setNewServiceAccountInfo(null);
        setIsReplacingServiceAccountInfo(false);
      }
    };

    if (dataSourceForm) {
      dataSourceForm.addEventListener('submit', handleFormSubmit);
      return () => dataSourceForm.removeEventListener('submit', handleFormSubmit);
    }
  });

  // Cache the service account JSON value and clear the form field.
  const handleReplaceServiceAccountJSON = () => {
    setIsReplacingServiceAccountInfo(true);

    if (newServiceAccountInfo === null) {
      setExistingServiceAccountInfo(auth.service_account_info);
    }

    const simulatedInputEvent = {
      target: {
        name: 'service_account_info',
        value: newServiceAccountInfo ?? '',
        type: 'text',
      },
    };

    onAuthChange(simulatedInputEvent);
  };

  // Restore the cached service account JSON value.
  const handleCancelReplaceServiceAccountJSON = () => {
    setIsReplacingServiceAccountInfo(false);
    setNewServiceAccountInfo(auth.service_account_info);

    const simulatedInputEvent = {
      target: {
        name: 'service_account_info',
        value: existingServiceAccountInfo,
        type: 'text',
      },
    };

    onAuthChange(simulatedInputEvent);
  };

  const hasExistingServiceAccount = !isOAuth && auth.service_account_info;

  if (isOAuth) {
    return null;
  }

  if (!isNewDataSource && hasExistingServiceAccount && !isReplacingServiceAccountInfo) {
    return (
      <Form.Field>
        <Form.Label htmlFor="service-account-info">Service Account JSON</Form.Label>
        <Form.Control>
          <Button onClick={handleReplaceServiceAccountJSON}>Replace Service Account JSON</Button>
        </Form.Control>
      </Form.Field>
    );
  }

  return (
    <Form.Field>
      <Form.Label htmlFor="service-account-info">Service Account JSON</Form.Label>
      <Form.Control>
        <Form.Textarea
          className="mb-2"
          placeholder="Paste service account json file contents here."
          type="text"
          name="service_account_info"
          onChange={onAuthChange}
          value={auth.service_account_info}
          autoComplete="off"
          id="service-account-info"
        />
        {isReplacingServiceAccountInfo && <Button onClick={handleCancelReplaceServiceAccountJSON}>Cancel</Button>}
      </Form.Control>
    </Form.Field>
  );
}

ServiceAccountInfo.propTypes = {
  auth: PropTypes.object.isRequired,
  isOAuth: PropTypes.bool.isRequired,
  onAuthChange: PropTypes.func.isRequired,
};

function GoogleBQOauthForm({ auth, database, isOAuth, onAuthChange, onChange, setIsOAuth }) {
  const authOptions = [
    { label: 'OAuth2', value: 'OAuth2' },
    { label: 'Service Account Info', value: 'service_account' },
  ];

  const selectedAuthMethod = isOAuth ? authOptions[0] : authOptions[1];

  return (
    <>
      <Form.Field>
        <Form.Label>Select Google authentication method</Form.Label>
        <Form.Control>
          <Select
            classNamePrefix="matik-select"
            onChange={(obj) => setIsOAuth(obj.value === 'OAuth2')}
            value={selectedAuthMethod}
            options={authOptions}
          />
        </Form.Control>
      </Form.Field>
      <Form.Field>
        <Form.Label htmlFor="project-id">Project ID</Form.Label>
        <Form.Control>
          <Form.Input
            placeholder="Project to connect to"
            type="text"
            name="database"
            id="project-id"
            onChange={onChange}
            value={database}
            autoComplete="off"
          />
        </Form.Control>
      </Form.Field>
      <ServiceAccountInfo auth={auth} isOAuth={isOAuth} onAuthChange={onAuthChange} />
    </>
  );
}

GoogleBQOauthForm.propTypes = {
  auth: PropTypes.object,
  database: PropTypes.string,
  isOAuth: PropTypes.bool,
  onAuthChange: PropTypes.func,
  onChange: PropTypes.func,
  setIsOAuth: PropTypes.func,
};

export default GoogleBQOauthForm;
export { ServiceAccountInfo, GoogleBQOauthForm };
