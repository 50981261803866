import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import Pluralize from 'pluralize';
import { Columns, Form } from 'react-bulma-components';
import { components } from 'react-select';

import Constants from 'components/Constants';
import utils from 'lib/utils';
import CheckboxWithLabel from 'components/shared/CheckboxWithLabel';
import DaySelector from 'components/shared/DaySelector';
import { Select, MenuNoSearch } from 'components/shared/FormSelect';
import Icon from 'components/lib/Icon';
import Modal from 'components/shared/modal/Modal';
import DateInputField from 'components/shared/paramFields/DateInputField';
import IntegrationFolderSelector from 'components/shared/IntegrationFolderSelector';
import API from 'lib/api';
import FormChange from 'lib/formChange';
import scheduler from 'lib/scheduler';
import { closeModal } from 'redux/ui/action';

const RecurrenceTypeOptions = [
  { label: 'Weekly', value: scheduler.RecurrenceType.WEEKLY },
  { label: 'Monthly', value: scheduler.RecurrenceType.MONTHLY },
  { label: 'Daily', value: scheduler.RecurrenceType.DAILY },
];

const EndOptions = [
  { label: 'After', value: 'after' },
  { label: 'On', value: 'on' },
];

const TimeOfDayOptions = [
  { label: 'AM', value: 'am' },
  { label: 'PM', value: 'pm' },
];

const HourOfDayOptions = [{ label: '12:00', value: 12 }];
for (let i = 1; i < 12; i++) {
  HourOfDayOptions.push({ label: i + ':00', value: i });
}

const RecurrenceTypeToScheduleTypeMapping = {
  [scheduler.RecurrenceType.DAILY]: scheduler.ScheduleType.EVERY_DAY,
  [scheduler.RecurrenceType.WEEKLY]: scheduler.ScheduleType.DAY_OF_WEEK,
  [scheduler.RecurrenceType.MONTHLY]: scheduler.ScheduleType.DAY_OF_MONTH,
};

function ScheduledFlowModal({
  canRefreshSelectAllValues,
  onScheduleSubmit,
  schedule,
  template,
  type,
  customFolderPlaceholder,
  includePdf,
  onPdfToggle,
  customFolder,
  onCustomFolderUpdate,
}) {
  const LDClient = useLDClient();
  const isCustomFoldersEnabled = LDClient?.variation('custom-presentation-generation-folders-run-level', false);
  const dispatch = useDispatch();
  const history = useHistory();
  const ui = useSelector((state) => state.ui);

  const [recurrenceType, setRecurrenceType] = useState(schedule?.recurrence_type ?? scheduler.RecurrenceType.WEEKLY);
  const [recurrencePeriod, setRecurrencePeriod] = useState(schedule?.recurrence_period ?? 1);
  const [startDate, setStartDate] = useState({
    value: schedule ? moment.utc(schedule.effective_start).local() : moment().startOf('day'),
  });
  const [endDate, setEndDate] = useState({
    value: schedule ? moment.utc(schedule.effective_end).local() : moment().add(1, 'month').startOf('day'),
  });
  const [hourOfDay, setHourOfDay] = useState(startDate.value.hour?.() % 12 || 12);
  const [timeOfDay, setTimeOfDay] = useState(
    startDate.value.hour?.() < 12 ? TimeOfDayOptions[0].value : TimeOfDayOptions[1].value,
  );
  const [dayOfWeek, setDayOfWeek] = useState(schedule ? startDate.value.day?.() : 1);
  const [dayOfMonth, setDayOfMonth] = useState(schedule ? startDate.value.date?.() : 1);
  const [endType, setEndType] = useState(schedule?.end_type ?? EndOptions[0].value);
  const [occurrences, setOccurrences] = useState(schedule?.end_after ?? 1);
  const [name, setName] = useState(schedule?.name ?? scheduler.getDefaultScheduleName(template.name, recurrenceType));
  const [refreshSelectAllValues, setRefreshSelectAllValues] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isScheduleExpanded, setScheduleExpanded] = useState(false);
  const [isGenerationExpanded, setGenerationExpanded] = useState(false);

  const snapshotData = () => {
    return {
      recurrenceType,
      recurrencePeriod,
      dayOfWeek,
      dayOfMonth,
      startDate,
      endDate,
      hourOfDay,
      timeOfDay,
      endType,
      occurrences,
      name,
      refreshSelectAllValues,
    };
  };

  const formChange = useRef(new FormChange(snapshotData()));

  const templateTypeMapping = {
    [Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_DOCS]: 'document',
    [Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SHEETS]: 'spreadsheet',
    [Constants.TEMPLATE_SOURCE_TYPES.EMAIL]: 'email',
  };

  const templateType = templateTypeMapping[template.source_type] || 'presentation';

  const titleType = utils.toTitleCase(templateType);
  const disabledFolderOptions = [
    Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_DOCS,
    Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SLIDES,
    Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SHEETS,
  ].includes(template.source_type)
    ? ['microsoft']
    : ['google'];

  useEffect(() => {
    if (canRefreshSelectAllValues && !refreshSelectAllValues) {
      setRefreshSelectAllValues(true);
    } else if (!canRefreshSelectAllValues && refreshSelectAllValues) {
      setRefreshSelectAllValues(false);
    }
  }, [canRefreshSelectAllValues]);

  useEffect(() => {
    if (!ui.modal && !ui.sidepane) {
      history.replace(`${history.location.pathname}`);
    }
  }, [ui.modal]);

  useEffect(() => {
    if (endType === 'on') {
      const firstOccurrence = getFirstOccurrence();
      const updatedEndDate = cloneDeep(endDate);
      if (firstOccurrence && endDate.value.isBefore?.(firstOccurrence, 'day') && !endDate.error) {
        updatedEndDate.value = firstOccurrence;
        updatedEndDate.error = '';
        setEndDate(updatedEndDate);
      }
    }
  }, [recurrenceType, recurrencePeriod, dayOfWeek, dayOfMonth, hourOfDay, timeOfDay, startDate, endDate]);

  const getHourValue = () => {
    let hour = hourOfDay;
    if (hour === 12) {
      if (timeOfDay === 'am') {
        hour = 0;
      }
    } else if (timeOfDay === 'pm') {
      hour = hourOfDay + 12;
    }
    return hour;
  };

  const getScheduleFromState = () => {
    const schedule = {
      name,
      effective_start: getFirstOccurrence(),
      recurrence_period: recurrencePeriod,
      recurrence_type: recurrenceType,
      schedule_type: RecurrenceTypeToScheduleTypeMapping[recurrenceType],
      task_type: scheduler.TaskType.SCHEDULED_BULK_PRESENTATIONS,
      end_type: endType,
    };

    if (recurrenceType === scheduler.RecurrenceType.MONTHLY) {
      schedule.schedule_target = schedule.effective_start.utc().date();
    } else if (recurrenceType === scheduler.RecurrenceType.WEEKLY) {
      schedule.schedule_target = schedule.effective_start.utc().day();
    } else {
      schedule.schedule_target = 0;
    }

    if (endType === 'after') {
      schedule.end_after = occurrences;
    } else {
      schedule.effective_end = endDate.value.hour(getHourValue() + 1);
    }

    if (ui.modal?.content) {
      schedule.csv_task_id = ui.modal.content;
    }

    return schedule;
  };

  const onSchedule = (e, skipPreview) => {
    e.preventDefault();
    const schedule = getScheduleFromState();
    if (schedule.csv_task_id) {
      setIsLoading(true);
      API.get(
        `/scheduled_tasks/${schedule.csv_task_id}/csv/`,
        (response) => {
          if (!skipPreview) {
            schedule.previewData = response.data.values_by_param_name;
          }
          setIsLoading(false);
          onScheduleSubmit(schedule, skipPreview, response.data?.should_refresh_select_all_values);
        },
        (error) => {
          setIsLoading(false);
          API.defaultError(error);
        },
      );
    } else {
      onScheduleSubmit(schedule, skipPreview, refreshSelectAllValues);
    }
  };

  const getFirstOccurrence = () => {
    if (!startDate.error) {
      const firstOccurrence = moment(startDate.value.format()).hour(getHourValue());
      const hasStartDatePassed = firstOccurrence.isBefore(moment());
      if (recurrenceType === scheduler.RecurrenceType.WEEKLY) {
        if (firstOccurrence.day() > dayOfWeek % 7 || (firstOccurrence.day() === dayOfWeek && hasStartDatePassed)) {
          firstOccurrence.add(recurrencePeriod, 'weeks');
        }
        firstOccurrence.day(dayOfWeek % 7);
      } else if (recurrenceType === scheduler.RecurrenceType.MONTHLY) {
        if (firstOccurrence.date() > dayOfMonth || (firstOccurrence.date() === dayOfMonth && hasStartDatePassed)) {
          firstOccurrence.add(recurrencePeriod, 'months');
        }
        firstOccurrence.date(firstOccurrence.daysInMonth() < dayOfMonth ? firstOccurrence.daysInMonth() : dayOfMonth);
      } else {
        if (hasStartDatePassed) {
          firstOccurrence.add(recurrencePeriod, 'days');
        }
      }
      return firstOccurrence;
    }
  };

  const onClose = (e) => {
    e.preventDefault();
    dispatch(closeModal());
    if (!ui.sidepane) {
      history.replace(`${history.location.pathname}`);
    }
  };

  const onNameChange = (e) => {
    e.preventDefault();
    setName(e.target.value);
  };

  const onPeriodChange = (e) => {
    e.preventDefault();
    setRecurrencePeriod(e.target.value ? parseInt(e.target.value) : e.target.value);
  };

  const onOccurrencesChange = (e) => {
    e.preventDefault();
    setOccurrences(e.target.value ? parseInt(e.target.value) : e.target.value);
  };

  const onEndTypeChange = (selected) => {
    setEndType(selected.value);
    const updatedEndDate = cloneDeep(endDate);
    if (selected.value === 'on' && endDate.value.isBefore(startDate.value)) {
      updatedEndDate.value = moment(startDate.value.format()).add({ months: 1, hours: 1 });
    } else {
      updatedEndDate.error = '';
    }
    setEndDate(updatedEndDate);
  };

  const onDateChange = (name, values) => {
    const updatedValues = {
      startDate: {
        ...startDate,
      },
      endDate: {
        ...endDate,
      },
    };
    updatedValues[name] = values[name];
    if (updatedValues[name].value.isBefore?.(moment(), 'day')) {
      updatedValues[name].error = "The selected date cannot come before today's date.";
    }
    if (endType === 'on') {
      const endBeforeStartError = 'The end date cannot come before the start date';
      if (
        updatedValues.endDate.value.isBefore?.(updatedValues.startDate.value, 'day') &&
        !updatedValues.endDate.error
      ) {
        updatedValues.endDate.error = endBeforeStartError;
      } else if (
        !updatedValues.endDate.value.isBefore?.(updatedValues.startDate.value, 'day') &&
        updatedValues.endDate.error === endBeforeStartError
      ) {
        updatedValues.endDate.error = '';
      }
    }
    setStartDate(updatedValues.startDate);
    setEndDate(updatedValues.endDate);
  };

  if (!template) {
    return null;
  }

  let scheduleSelector = null;
  if (recurrenceType === scheduler.RecurrenceType.WEEKLY) {
    scheduleSelector = (
      <Columns>
        <Columns.Column>
          <DaySelector selectedValue={dayOfWeek} onChange={(selectedValue) => setDayOfWeek(selectedValue)} />
        </Columns.Column>
      </Columns>
    );
  } else if (recurrenceType === scheduler.RecurrenceType.MONTHLY) {
    const IndicatorsContainer = (props) => {
      return (
        <div className="is-flex">
          <components.IndicatorsContainer {...props} /> <span className="input-addon">of the month</span>
        </div>
      );
    };
    const dayOfMonthOptions = [...Array(28).keys()].map((x) => {
      return { label: x + 1, value: x + 1 };
    });
    // Special case for Last day of the month (28 - 31)
    dayOfMonthOptions.unshift({ label: 'Last day', value: 31 });

    scheduleSelector = (
      <Columns>
        <Columns.Column className="help is-flex is-vertical-centered">
          <Form.Help>on the</Form.Help>
        </Columns.Column>
        <Columns.Column className="is-flex">
          <Select
            classNamePrefix="matik-select"
            className="is-flex-grow-1"
            options={dayOfMonthOptions}
            value={dayOfMonthOptions.find((option) => option.value === dayOfMonth) ?? dayOfMonthOptions[0]}
            onChange={(selected) => setDayOfMonth(selected.value)}
            componentsToAdd={{ Menu: MenuNoSearch, IndicatorsContainer }}
          />
        </Columns.Column>
      </Columns>
    );
  }
  const dateInputValues = {
    startDate: {
      ...startDate,
    },
    endDate: {
      ...endDate,
    },
  };

  const hasChanged = formChange.current.hasChanged(snapshotData());

  const isEmailTemplate = template.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL;
  const units = Pluralize(scheduler.RecurrenceMapping[recurrenceType], recurrencePeriod, false);
  const isUpdate = type === 'update';
  const primaryButtonText = isUpdate ? 'Update Scheduled Flow' : 'Preview Scheduled Flow';
  const secondaryButtonText = isUpdate ? null : 'Schedule';
  const areButtonsDisabled = !!(
    recurrencePeriod < 1 ||
    (endType === 'after' && occurrences < 1) ||
    !name ||
    startDate.error ||
    endDate?.error ||
    (!hasChanged && isUpdate)
  );
  let scheduleDescription = '';
  const firstOccurrence = getFirstOccurrence();
  if (firstOccurrence) {
    const firstOccurrenceString =
      moment().year() === firstOccurrence.year()
        ? firstOccurrence.format('MMMM Do')
        : firstOccurrence.format('MMMM Do, YYYY');
    scheduleDescription =
      occurrences === 1
        ? `Your flow will run on ${firstOccurrenceString}`
        : `Your first generation will run on ${firstOccurrenceString}, and then run ${scheduler
            .getSentenceFromSchedule(getScheduleFromState(), true)
            .replace('Runs', ' ')}`;
  }
  const isGenerationOptionsVisible =
    isCustomFoldersEnabled &&
    template.source_type !== Constants.TEMPLATE_SOURCE_TYPES.EMAIL &&
    template.source_type !== Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT &&
    template.source_type !== Constants.TEMPLATE_SOURCE_TYPES.WORD;

  return (
    <Modal
      additionalModalClass="scheduled"
      show={ui.modal?.name === 'scheduledFlowModal'}
      onClose={onClose}
      title={
        isEmailTemplate
          ? `${isUpdate ? 'Update' : 'Setup'} Send Email Schedule`
          : `${isUpdate ? 'Update' : 'Setup'} Bulk Generation Schedule`
      }
      showDefaultFooter={true}
      footerIsSticky={false}
      primaryButtonText={primaryButtonText}
      primaryButtonOnClick={(e) => onSchedule(e, isUpdate)}
      primaryButtonDisabled={areButtonsDisabled}
      primaryButtonLoading={isLoading}
      secondaryButtonText={secondaryButtonText}
      secondaryButtonOnClick={(e) => onSchedule(e, true)}
      secondaryButtonDisabled={isUpdate ? null : areButtonsDisabled}
      secondaryButtonLoading={isLoading}
      tertiaryButtonText="Cancel"
      tertiaryButtonOnClick={onClose}
    >
      <Form.Label>Schedule Cadence</Form.Label>
      <Form.Help className="mbs">This should run:</Form.Help>
      <Columns>
        <Columns.Column size="one-third">
          <Select
            classNamePrefix="matik-select"
            options={RecurrenceTypeOptions}
            value={RecurrenceTypeOptions.find((option) => option.value === recurrenceType)}
            onChange={(selected) => setRecurrenceType(selected.value)}
          />
        </Columns.Column>
        <Columns.Column className="is-flex content-centered">
          <Form.Help className="prm">every</Form.Help>
          <div>
            <div className="input-with-addon">
              <Form.Input type="number" min={1} value={recurrencePeriod} onChange={onPeriodChange} />
              <div className="input-addon">{units}</div>
            </div>
            {recurrencePeriod < 1 && <Form.Help color="danger">Number of {units} required</Form.Help>}
          </div>
        </Columns.Column>
      </Columns>
      {scheduleSelector}
      <Columns>
        <Columns.Column className="help is-flex is-vertical-centered">
          <Form.Help>at</Form.Help>
        </Columns.Column>
        <Columns.Column>
          <Select
            classNamePrefix="matik-select"
            options={HourOfDayOptions}
            value={HourOfDayOptions.find((option) => option.value === hourOfDay)}
            onChange={(selected) => setHourOfDay(selected.value)}
          />
        </Columns.Column>
        <Columns.Column>
          <Select
            classNamePrefix="matik-select"
            options={TimeOfDayOptions}
            value={TimeOfDayOptions.find((option) => option.value === timeOfDay)}
            onChange={(selected) => setTimeOfDay(selected.value)}
          />
        </Columns.Column>
      </Columns>

      <button
        className="relative mb-4 py-2 pr-9 w-full text-left"
        onClick={() => setScheduleExpanded(!isScheduleExpanded)}
      >
        <div className="absolute inset-y-0 right-3 flex gap-1 items-center">
          <Icon name={isScheduleExpanded ? 'chevron_up' : 'chevron_down'} />
        </div>
        Schedule Options
      </button>
      <div className={`relative ${!isScheduleExpanded && 'hidden'} mb-4`}>
        <Form.Field className="pbs">
          <Form.Label>Schedule Start</Form.Label>
          <Form.Help className="mbs">Starting on:</Form.Help>
          <DateInputField input={{ name: 'startDate' }} inputValues={dateInputValues} onChange={onDateChange} />
          {startDate.error && <Form.Help color="danger">{startDate.error}</Form.Help>}
        </Form.Field>
        <Form.Field className="pbs">
          <Form.Label>Schedule End</Form.Label>
          <Form.Help className="mbs">And ending:</Form.Help>
          <Columns>
            <Columns.Column size="one-third">
              <Select
                classNamePrefix="matik-select"
                options={EndOptions}
                value={EndOptions.find((option) => option.value === endType)}
                onChange={onEndTypeChange}
              />
            </Columns.Column>
            {endType === 'after' ? (
              <Columns.Column>
                <div className="input-with-addon">
                  <Form.Input type="number" min={1} value={occurrences} onChange={onOccurrencesChange} />
                  <div className="input-addon">{Pluralize('occurrence', occurrences, false)}</div>
                </div>
                {occurrences < 1 && <Form.Help color="danger">Schedule occurrences should be greater than 0</Form.Help>}
              </Columns.Column>
            ) : (
              <Columns.Column>
                <DateInputField input={{ name: 'endDate' }} inputValues={dateInputValues} onChange={onDateChange} />
                {endDate.error && <Form.Help color="danger">{endDate.error}</Form.Help>}
              </Columns.Column>
            )}
          </Columns>
        </Form.Field>
        <Form.Field className="pbs">
          <Form.Label>Schedule Name (Optional)</Form.Label>
          <Form.Input type="text" value={name} onChange={onNameChange} />
          {!name && <Form.Help color="danger">Name is required to create schedule</Form.Help>}
        </Form.Field>
        {(canRefreshSelectAllValues || isUpdate) && (
          <Form.Field className="pbs">
            <CheckboxWithLabel
              checked={refreshSelectAllValues}
              disabled={isUpdate}
              id="refresh-select-all"
              label="Refresh values for inputs set to “Select All” on scheduled generation"
              name="refresh_select_all"
              onChange={(e) => setRefreshSelectAllValues(e.target.checked)}
            />
          </Form.Field>
        )}
      </div>

      {isGenerationOptionsVisible && (
        <>
          <button
            className="relative mb-4 py-2 pr-9 w-full text-left"
            onClick={() => setGenerationExpanded(!isGenerationExpanded)}
          >
            <div className="absolute inset-y-0 right-3 flex gap-1 items-center">
              <Icon name={isGenerationExpanded ? 'chevron_up' : 'chevron_down'} />
            </div>
            Generation Options
          </button>
          <div className={`relative ${!isGenerationExpanded && 'hidden'} mb-4`}>
            <CheckboxWithLabel
              checked={includePdf}
              id="include-pdf-checkbox"
              label="Include PDF"
              onChange={onPdfToggle}
            />
            <div className="mt-3 mb-3">
              <p className="mb-1">{titleType} will be saved to:</p>
              <IntegrationFolderSelector
                currentFolder={customFolder}
                onFolderSelect={onCustomFolderUpdate}
                disabledOptions={disabledFolderOptions}
                placeholder={customFolderPlaceholder}
              />
            </div>
          </div>
        </>
      )}

      {!areButtonsDisabled && !startDate.value.isBefore?.(moment(), 'day') && (
        <div className="info-caption is-vertical-centered">
          <Icon name="info" size={16} theme="filled" />
          {scheduleDescription}
        </div>
      )}
    </Modal>
  );
}

ScheduledFlowModal.propTypes = {
  canRefreshSelectAllValues: PropTypes.bool.isRequired,
  onScheduleSubmit: PropTypes.func,
  schedule: PropTypes.object,
  template: PropTypes.object,
  type: PropTypes.string,
  customFolderPlaceholder: PropTypes.string,
  includePdf: PropTypes.bool,
  onPdfToggle: PropTypes.func,
  customFolder: PropTypes.object,
  onCustomFolderUpdate: PropTypes.func,
};

export default ScheduledFlowModal;
