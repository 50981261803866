import React from 'react';
import PropTypes from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Connector from 'components/producer/conditions/Connector';
import AvatarGroup from 'components/lib/AvatarGroup';
import IconPill from 'components/lib/IconPill';
import ListItem from 'components/lib/ListItem';
import ListItemDynamicContent from 'components/producer/dynamicContent/ListItemDynamicContent';

function AssetItemDynamicContent({ content, handleClick, onHighlight, onUnhighlight, permissionIssues }) {
  const flags = useFlags();
  const templateAccessorsWithoutDynamicContentAccess = permissionIssues?.[content.matchingContent?.id] ?? [];
  let connector;
  const isSubcontent = content.isChild && content.name !== content.matchingContent?.name;
  if (content.isChild) {
    if (content.lastChild) {
      connector = (
        <div className="flex">
          <Connector className="w-8 -mt-2" up right roundedUpRight />
          <Connector className="w-2 -mt-[10px]" left right />
        </div>
      );
    } else {
      connector = (
        <div className="flex">
          <Connector className="w-8 -mt-2" up right down roundedUpRight />
          <Connector className="w-2 -mt-[10px]" left right />
        </div>
      );
    }
  }
  let item;
  if (!content.matchingContent) {
    item = (
      <ListItem
        icon={<IconPill iconColor="yellow-500" iconName="warning" size="s" iconTheme="filled" theme="square" />}
        subtitle={<span className="text-yellow-500">Does not exist</span>}
        title={content.name}
      />
    );
  } else if (isSubcontent) {
    item = (
      <ListItem
        icon={
          <IconPill
            iconName="subcontent"
            text={`Returns the ${content.name} field from ${content.matchingContent.name}`}
            size="s"
            theme="square"
          />
        }
        subtitle={content.formats}
        title={content.name}
      />
    );
  } else if (flags.dataSourcePermissions && templateAccessorsWithoutDynamicContentAccess.length) {
    item = (
      <ListItem
        icon={<IconPill iconColor="red-500" iconName="error_circle" size="s" iconTheme="filled" theme="square" />}
        subtitle={<span className="text-red-500">Access issues</span>}
        title={content.name}
        metadata={<AvatarGroup size="s" limit={2} avatars={templateAccessorsWithoutDynamicContentAccess} />}
      />
    );
  } else if (flags.dataSourcePermissions && Object.keys(content.matchingContent).length === 2) {
    // {id,name} only means inaccessible content
    item = (
      <ListItem
        icon={<IconPill iconColor="red-500" iconName="error_circle" size="s" iconTheme="filled" theme="square" />}
        subtitle={<span className="text-red-500">Access issues</span>}
        title={content.name}
      />
    );
  } else {
    item = (
      <ListItemDynamicContent
        content={content.isChild ? { ...content.matchingContent, name: content.name } : content.matchingContent}
        subtitle={content.formats}
      />
    );
  }
  const contentName = content.toContentName();
  return (
    <div className="flex" onMouseEnter={() => onHighlight(content.slideNums, contentName)} onMouseLeave={onUnhighlight}>
      {connector}
      <div
        className="hover:border-matik-green border-grey-300 border rounded px-4 py-3 flex-1 cursor-pointer flex min-w-0"
        onClick={() => handleClick(content)}
      >
        {item}
      </div>
    </div>
  );
}

AssetItemDynamicContent.propTypes = {
  content: PropTypes.object,
  handleClick: PropTypes.func,
  onHighlight: PropTypes.func,
  onUnhighlight: PropTypes.func,
  permissionIssues: PropTypes.object,
};

export default AssetItemDynamicContent;
