import React, { useState } from 'react';
import { Tag } from 'react-bulma-components';
import PropTypes from 'prop-types';
import API from '../../lib/api';
import { ReactComponent as WandIcon } from '../../images/icons/wand-20x20-filled.svg';
import { MAlert } from './Alerts';
import assistant from '../../lib/asssistant';

function ExplainerButton({ onGenerate, query, queryUse, name }) {
  const [isLoading, setIsLoading] = useState(false);

  const isDisabled = !query?.query_string;

  const explainQuery = (e) => {
    e.preventDefault();
    if (isLoading || isDisabled) {
      return;
    }

    setIsLoading(true);
    const onSuccess = (response, onComplete) => {
      if (response.data.status === 'done') {
        setIsLoading(false);
        onGenerate(response.data.result);
        onComplete();
      } else if (response.data.status === 'error') {
        setIsLoading(false);
        MAlert(`Error generating description: ${response.data.message}`, 'Error', 'error');
        onGenerate(response.data.result);
        onComplete();
      }
    };
    const onError = (err) => {
      setIsLoading(false);
      API.defaultError(err);
    };

    assistant.explainQuery(query, name, queryUse, onSuccess, onError);
  };

  let generateButtonText = 'Generate using AI';
  let generateAiIcon = <WandIcon className="magic-gradient mrs" />;
  if (isLoading) {
    generateButtonText = 'Loading...';
  }

  let classes = 'has-cursor-pointer is-rounded has-light-gray-border background-white pam';
  let tooltip = undefined;
  if (isDisabled) {
    classes = 'has-light-gray-border pam is-rounded';
    tooltip = 'You must populate a query or API form values to generate a description.';
  } else if (query && !query.id) {
    tooltip =
      'Generate a description based on the current query settings. You can always re-generate the description ' +
      'to account for connections made to other Matik elements after saving and/or changes to the query';
  } else {
    tooltip = 'Generate a description based on the current query and metadata.';
  }

  return (
    <div data-tooltip-content={tooltip} data-tooltip-id="matik-tooltip">
      <Tag className={classes} onClick={explainQuery}>
        {generateAiIcon}
        {generateButtonText}
      </Tag>
    </div>
  );
}

ExplainerButton.propTypes = {
  name: PropTypes.string,
  query: PropTypes.object,
  queryUse: PropTypes.string,
  onGenerate: PropTypes.func.isRequired,
};

export default ExplainerButton;
