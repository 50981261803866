import React, { useContext, useState } from 'react';
import { CreatableSelect, Select } from '../../../shared/FormSelect';
import { Form } from 'react-bulma-components';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { connect } from 'react-redux';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import { mapDispatchToProps } from 'redux/ui/dispatchers';
import RenamableModal from '../RenamableModal';
import Constants from '../../../Constants';
import SenderApiReturnFields from './SenderApiReturnFields';
import { DynamicContentContext } from '../DynamicContentContext';

function ApiReturnFields(props) {
  const dynamicContentContext = useContext(DynamicContentContext);

  const transformFieldOptions = (fieldOptions) => {
    const options = fieldOptions.map((option) => {
      if (option.type === 'group') {
        const groupOptionsArray = option.options.map((crmOption) => {
          return { value: crmOption.name, ...crmOption };
        });
        return { label: option.label, type: option.type, options: groupOptionsArray };
      } else {
        return { label: option.name, value: option.name, type: option.type };
      }
    });
    return options;
  };

  const flattenFieldOptions = (fieldOptions) => {
    return (
      fieldOptions
        .map((fieldOption) => {
          if (fieldOption.type === 'group') {
            return fieldOption.options;
          }
          return fieldOption;
        })
        .flat()
        // sort in the order the return field was added
        .sort(
          (optionA, optionB) => props.returnFields?.indexOf(optionA.name) - props.returnFields?.indexOf(optionB.name),
        )
    );
  };

  const transformFieldsToReturn = (fieldOptions) => {
    const flattenedFieldOptions = flattenFieldOptions(fieldOptions);

    return flattenedFieldOptions
      .filter((option) => option.selected)
      .map((fieldOption) => ({
        label: fieldOption.returnFieldLabel || fieldOption.name,
        value: fieldOption.name,
        toggleRenameModal: (e) => toggleRenameModal(e, fieldOption.name),
        state: fieldOption.hideable && fieldOption.hidden ? 'hidden' : 'default',
        rightIconName: props.canHide ? (fieldOption.hidden ? 'eye_closed' : 'eye') : '',
        iconName: fieldOption.iconName || '',
        inErrorState: fieldOption.inErrorState || false,
        errorMessage: fieldOption.errorMessage || '',
        iconToolTip: fieldOption.iconToolTip || '',
        rightIconToolTip: props.canHide ? fieldOption.hideToolTip : '',
        onRemoveToolTip: fieldOption.removeToolTip || '',
        displayName: fieldOption.alias || '',
        isRenamable: !fieldOption.hidden,
        rightIconOnClick:
          props.canHide && (fieldOption.hideable || fieldOption.hidden)
            ? () =>
                fieldOption.hidden
                  ? props.onReturnFieldShow(fieldOption.name)
                  : props.onReturnFieldHide(fieldOption.name)
            : undefined,
      }));
  };

  const optionsProp = transformFieldOptions(props.fieldOptions);
  const returnFields = transformFieldsToReturn(props.fieldOptions);

  const SelectComponent = props.canCreateReturnField ? CreatableSelect : Select;
  const errorClassName = props.inErrorState ? 'select-error' : '';

  const [renameField, setRenameField] = useState(null);
  const returnFieldsMapping = Object.fromEntries(
    flattenFieldOptions(props.fieldOptions).map((fo) => [fo.name, fo.alias]),
  );
  const renameAlias = renameField && returnFieldsMapping[renameField];

  const toggleRenameModal = (e, fieldName) => {
    e.preventDefault();
    e.stopPropagation();
    if (props.ui.modal?.name === 'renamableModal') {
      props.closeModal();
    } else {
      setRenameField(fieldName);
      props.openModal('renamableModal');
    }
  };

  const onRenameModalSave = (fieldName, displayName) => {
    props.onReturnFieldsMappingUpdate(fieldName, displayName);
    props.closeModal();
  };

  const onSenderReturnFieldsChange = async (obj, action) => {
    const senderFieldName = action.name;
    const returnFieldName = obj.value;
    await dynamicContentContext.onSenderFieldUpdate(senderFieldName, returnFieldName);
    const senderReturnFields = new Set(Object.values(dynamicContentContext.multiFieldMapping));
    const selectedOptions = [...senderReturnFields].map((fieldName) => ({ label: fieldName, value: fieldName }));
    props.onReturnFieldSelectAll(selectedOptions);
  };

  const onReturnFieldsChange = (obj, action) => {
    if (action.action === 'select-option') {
      props.onReturnFieldAdd(action.option);
    } else if (action.action === 'select-all-options') {
      props.onReturnFieldSelectAll(action.option);
    } else if (action.action === 'remove-value') {
      props.onReturnFieldRemove(action.removedValue);
    } else if (action.action === 'clear') {
      props.onReturnFieldClear(action.removedValues);
    } else if (action.action === 'create-option') {
      props.onReturnFieldAdd(action);
    }
  };

  if (dynamicContentContext.dynamicContentType === Constants.DynamicContentTypes.SENDER) {
    return <SenderApiReturnFields onReturnFieldChange={onSenderReturnFieldsChange} options={optionsProp} />;
  }

  return (
    <React.Fragment>
      <Form.Field className="mbl">
        <Form.Label>Fields to return</Form.Label>
        <Form.Help>Select field(s) to return</Form.Help>
        <Form.Control>
          <SelectComponent
            classNamePrefix="matik-select"
            isMulti={true}
            noOptionsMessage={() => (props.noOptionsMessage ? props.noOptionsMessage : 'No options')}
            options={optionsProp}
            isDisabled={props.isReadOnly}
            onChange={onReturnFieldsChange}
            placeholder="Select Return Fields"
            classNames={{
              control: () => errorClassName,
            }}
            componentsToAdd={{
              Option: components.Option,
              ValueContainer: components.ValueContainer,
            }}
            value={returnFields}
          />
          {props.inErrorState && <Form.Help color="danger">{props.errorMessage}</Form.Help>}
        </Form.Control>
      </Form.Field>
      <RenamableModal
        show={props.ui.modal?.name === 'renamableModal'}
        key={renameField}
        onClose={props.closeModal}
        initialInput={renameAlias}
        fieldName={renameField}
        onSave={onRenameModalSave}
      />
    </React.Fragment>
  );
}

ApiReturnFields.propTypes = {
  canCreateReturnField: PropTypes.bool,
  errorMessage: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  fieldOptions: PropTypes.array,
  isReadOnly: PropTypes.bool,
  onReturnFieldAdd: PropTypes.func,
  onReturnFieldSelectAll: PropTypes.func,
  onReturnFieldRemove: PropTypes.func,
  onReturnFieldClear: PropTypes.func,
  onReturnFieldHide: PropTypes.func,
  onReturnFieldShow: PropTypes.func,
  onReturnFieldsMappingUpdate: PropTypes.func,
  onSenderReturnFields: PropTypes.func,
  inErrorState: PropTypes.bool,
  ui: PropTypes.object,
  closeModal: PropTypes.func,
  openModal: PropTypes.func,
  canHide: PropTypes.bool,
  returnFields: PropTypes.array,
};

export default connect(mapUiStateToProps, mapDispatchToProps)(ApiReturnFields);
