import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import TagTree, { SlideTag } from 'lib/tagTree';
import utils from 'lib/utils';
import API from 'lib/api';
import inputs from 'lib/inputs';
import { Section } from 'react-bulma-components';
import TemplateSlides from 'components/producer/templates/TemplateSlides';
import { ContentContext } from 'components/producer/templates/ContentContext';
import SlidesTemplateSidebar, { TABS } from 'components/producer/templates/SlidesTemplateSidebar';
import SlideLoopModal from 'components/producer/templates/SlideLoopModal';
import LoopingUpsellWrapper from 'components/producer/templates/LoopingUpsell';
import { useTemplateContent, useTemplateLoopMutator } from 'lib/hooks/useTemplate';
import { useInaccessibleContent } from 'lib/hooks/useTemplateTag';

function SlidesTemplateComponents({
  allDynamicContent,
  canEdit,
  currentTemplate,
  onUpdateTemplateFile,
  resetSelectedSlides,
  selectedSlides,
  selectedSlidesBySlideNum,
  setShowSingleSlide,
  showSingleSlide,
  slideIdxByInputName,
  status,
  toggleAllSlides,
  toggleSlideSelect,
  updateLoopIcons,
  onContentClick,
  onInputClick,
  onInsertLibrarySlide,
  onRemoveLibrarySlide,
}) {
  const { upsert: upsertSlideLoop, del: deleteSlideLoop } = useTemplateLoopMutator();

  const [highlightedContentName, setHighlightedContentName] = useState(null);
  const [highlightedSlideNums, setHighlightedSlideNums] = useState([]);
  const [filteredSlides, setFilteredSlides] = useState([]);
  const [selectedContent, setSelectedContent] = useState(null);
  const [selectedSlideTag, setSelectedSlideTag] = useState(null);

  const [editSlideLoop, setEditSlideLoop] = useState(null);
  const [viewingSlideId, setViewingSlideId] = useState(null);

  const sidebarRef = useRef();

  const { data: templateContent, isPending: isContentFetching } = useTemplateContent(
    currentTemplate?.id,
    currentTemplate?.deleted,
  );

  useEffect(() => {
    API.track('input_form_in_template_sidepane');
  }, []);

  const handleGridClick = (e) => {
    // if any clicks are generated from TemplateSlides container itself, deselect all.
    if (e.target === e.currentTarget) {
      toggleAllSlides(false);
    }
  };

  const handleLoopDelete = (loop) => {
    return deleteSlideLoop(loop)
      .then(() => updateLoopIcons())
      .catch(API.defaultError);
  };

  const selectSlideTag = (currentTag, elementId, selection) => {
    if (currentTag) {
      const [tagName, contentName, subcontent, formats] = utils.getTagParts(currentTag);
      setSelectedSlideTag(new SlideTag(elementId, selection, contentName, tagName, subcontent, formats));
    } else {
      setSelectedSlideTag(new SlideTag(elementId, selection));
    }
  };

  const deselectSlideTag = () => {
    setSelectedSlideTag(null);
  };

  const { dynamicContentTags, templateInputs, selectedSlidesInputs, selectedTagTree } =
    findTemplateContentTagsAndInputs(
      isContentFetching,
      currentTemplate,
      templateContent,
      selectedSlides,
      selectedSlidesBySlideNum,
      allDynamicContent,
    );

  const isPopulatingInaccessible = useInaccessibleContent(dynamicContentTags);

  const providerValue = {
    selectedContent: selectedContent,
    selectContent: setSelectedContent,
    highlightedContentName: highlightedContentName,
    highlightContentName: setHighlightedContentName,
    selectSlideTag: selectSlideTag,
    deselectSlideTag: deselectSlideTag,
    selectedSlideTag: selectedSlideTag,
  };

  const handleInputLoopClick = (input) => {
    setEditSlideLoop(input);
  };

  const viewingSlide = currentTemplate?.slides?.find((slide) => slide.id === viewingSlideId);

  return (
    <>
      <ContentContext.Provider value={providerValue}>
        <div className="template-wrapper">
          <div className="template-components">
            <LoopingUpsellWrapper interceptPropName="onInputLoopClick">
              <SlidesTemplateSidebar
                ref={sidebarRef}
                dynamicContentTags={dynamicContentTags}
                inputsInMatchingContent={selectedSlidesInputs}
                isContentFetching={isContentFetching || isPopulatingInaccessible}
                currentTemplate={currentTemplate}
                selectedSlides={selectedSlides}
                toggleSlideSelect={toggleSlideSelect}
                handleHighlightSlideNums={setHighlightedSlideNums}
                onInputLoopClick={handleInputLoopClick}
                onContentClick={onContentClick}
                onInputClick={onInputClick}
                onFilterSlides={setFilteredSlides}
                viewingSlide={viewingSlide}
              />
            </LoopingUpsellWrapper>
          </div>
          <Section
            className={`is-paddingless template-slides ${currentTemplate.source_type} ${
              showSingleSlide ? 'ssv' : 'slide-grid'
            }`}
            onClick={handleGridClick}
          >
            <TemplateSlides
              currentTemplate={currentTemplate}
              status={status}
              toggleSlide={toggleSlideSelect}
              highlightedSlideNums={highlightedSlideNums}
              selectedSlides={selectedSlides}
              dynamicContentTags={dynamicContentTags}
              allDynamicContent={allDynamicContent}
              onUpdateTemplateFile={onUpdateTemplateFile}
              canEdit={canEdit}
              matchingInputs={selectedSlidesInputs}
              tags={selectedTagTree}
              slideIdxByInputName={slideIdxByInputName}
              resetSelectedSlides={resetSelectedSlides}
              showSingleSlide={showSingleSlide}
              toggleAllSlides={toggleAllSlides}
              setShowSingleSlide={setShowSingleSlide}
              onLoopClick={handleInputLoopClick}
              onInsertLibrarySlide={onInsertLibrarySlide}
              onRemoveLibrarySlide={onRemoveLibrarySlide}
              filteredSlides={filteredSlides}
              onConditionButtonClick={() => sidebarRef.current.setCurrentTab(TABS.CONDITIONS)}
              viewingSlide={viewingSlide}
              setViewingSlideId={setViewingSlideId}
            />
          </Section>
        </div>
      </ContentContext.Provider>
      {editSlideLoop && (
        <SlideLoopModal
          show
          input={editSlideLoop}
          template={currentTemplate}
          status={status}
          onClose={() => setEditSlideLoop(null)}
          onLoopDelete={handleLoopDelete}
          onLoopUpdate={upsertSlideLoop}
          inputsByName={templateInputs}
        />
      )}
    </>
  );
}

SlidesTemplateComponents.propTypes = {
  allDynamicContent: PropTypes.object,
  canEdit: PropTypes.bool,
  currentTemplate: PropTypes.object,
  onUpdateTemplateFile: PropTypes.func,
  resetSelectedSlides: PropTypes.func,
  selectedSlides: PropTypes.array,
  selectedSlidesBySlideNum: PropTypes.object,
  setShowSingleSlide: PropTypes.func,
  showSingleSlide: PropTypes.bool,
  slideIdxByInputName: PropTypes.object,
  status: PropTypes.object,
  toggleAllSlides: PropTypes.func,
  toggleSlideSelect: PropTypes.func,
  updateLoopIcons: PropTypes.func,
  onContentClick: PropTypes.func,
  onInputClick: PropTypes.func,
  onInsertLibrarySlide: PropTypes.func,
  onRemoveLibrarySlide: PropTypes.func,
};

export default SlidesTemplateComponents;

const flattenTags = (tagTree) => {
  return tagTree?.flattenTree((a) => {
    if (!a.matchingContent) {
      return -1;
    } else {
      return 1;
    }
  });
};
const findTemplateContentTagsAndInputs = (
  isContentFetching,
  currentTemplate,
  templateContent,
  selectedSlides,
  selectedSlidesBySlideNum,
  allDynamicContent,
) => {
  let selectedTagTree = new TagTree();
  let allTagTree = new TagTree();
  let flattenedContentTags = [];
  if (!isContentFetching) {
    allTagTree = utils.getDynamicContentTags(currentTemplate, templateContent, allDynamicContent);
    if (selectedSlides && selectedSlides.length > 0) {
      selectedTagTree = utils.getDynamicContentTagsForSelectedSlides(
        currentTemplate,
        templateContent,
        currentTemplate.slides,
        selectedSlidesBySlideNum,
        allDynamicContent,
      );
    } else {
      selectedTagTree = allTagTree;
    }
    flattenedContentTags = flattenTags(selectedTagTree);
  }

  const selectedSlidesTags = selectedTagTree.getTagNodes();
  const inputsOnSelectedSlides = inputs.getAllInputs(
    selectedSlidesTags,
    allDynamicContent,
    currentTemplate,
    templateContent,
    selectedSlides?.length ? selectedSlides : null,
  );
  const inputsOnTemplate = inputs.getAllInputs(
    allTagTree.getTagNodes(),
    allDynamicContent,
    currentTemplate,
    templateContent,
    null,
  );

  return {
    dynamicContentTags: flattenedContentTags,
    templateInputs: inputsOnTemplate,
    selectedSlidesInputs: inputsOnSelectedSlides,
    selectedTagTree: selectedTagTree,
  };
};
