import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Heading } from 'react-bulma-components';
import { isEqual } from 'lodash';
import CRMForm from './forms/CRMForm';
import TestResult from '../../shared/TestResult';
import SampleData from './SampleData';
import WithTestQuery from '../../shared/WithTestQuery';
import utils from '../../../lib/utils';
import LookerForm from './forms/LookerForm';
import TableauForm from './forms/TableauForm';
import RestForm from './forms/RestForm';
import { Select } from '../../shared/FormSelect';
import { mapApiInfoStateToProps } from '../../../redux/dataSources/stateMappers';
import { mapDispatchToProps } from '../../../redux/dataSources/dispatchers';
import Constants from '../../Constants';
import SpreadSheetCalculatorForm from './forms/SpreadSheetCalculatorForm';
import SmallLoader from '../../shared/SmallLoader';
import GainsightForm from './forms/GainsightForm';
import ProductboardForm from './forms/ProductboardForm';
import PowerBIForm from './forms/PowerBIForm';
import dataSources from '../../../lib/dataSources';
import Button from '../../lib/Button';

class InputFormApi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterDuplicates:
        this.props.query_obj &&
        this.props.query_obj.query_string &&
        utils.isValidJSON(this.props.query_obj.query_string)
          ? JSON.parse(this.props.query_obj.query_string).filterDuplicates
          : false,
    };
  }
  componentDidMount() {
    if (this.props.dataSource) {
      if (this.props.dataSource.type === 'salesforce') {
        this.props.fetchReportCountFromApi(this.props.query_obj.data_source.id);
        this.props.fetchObjectCountFromApi(this.props.query_obj.data_source.id);
      } else if (this.props.dataSource.type === 'looker') {
        this.props.fetchApiInfoIfNeeded(this.props.query_obj.data_source.id, 'dashboards');
        this.props.fetchApiInfoIfNeeded(this.props.query_obj.data_source.id, 'models');
      } else if (this.props.dataSource.type === 'tableau') {
        this.props.fetchApiInfoIfNeeded(this.props.query_obj.data_source.id);
      } else if (this.props.dataSource.type === Constants.DATA_SOURCE_TYPES.hubspot) {
        this.props.fetchApiInfoIfNeeded(this.props.query_obj.data_source.id);
      } else if (this.props.dataSource.type === 'gainsight') {
        this.props.fetchApiInfoIfNeeded(this.props.dataSource.id, 'objects');
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.dataSource, this.props.dataSource)) {
      if (this.props.query_obj.data_source.type === 'salesforce') {
        this.props.fetchReportCountFromApi(this.props.query_obj.data_source.id);
        this.props.fetchObjectCountFromApi(this.props.query_obj.data_source.id);
      } else if (this.props.query_obj.data_source.type === 'looker') {
        this.props.fetchApiInfoIfNeeded(this.props.query_obj.data_source.id, 'dashboards');
        this.props.fetchApiInfoIfNeeded(this.props.query_obj.data_source.id, 'models');
      } else if (this.props.query_obj.data_source.type === 'tableau') {
        this.props.fetchApiInfoIfNeeded(this.props.query_obj.data_source.id);
      } else if (this.props.query_obj.data_source.type === Constants.DATA_SOURCE_TYPES.hubspot) {
        this.props.fetchApiInfoIfNeeded(this.props.query_obj.data_source.id);
      } else if (this.props.dataSource.type === 'gainsight') {
        this.props.fetchApiInfoIfNeeded(this.props.dataSource.id, 'objects');
      }
    }
  }

  render() {
    const queryString =
      this.props.query_obj && this.props.query_obj.query_string ? this.props.query_obj.query_string : '';
    const queryObj = queryString && utils.isValidJSON(queryString) ? JSON.parse(queryString) : {};

    let body = '';
    if (this.props.isFetching) {
      body = <SmallLoader />;
    } else {
      if (
        this.props.query_obj.data_source.type === Constants.DATA_SOURCE_TYPES.salesforce ||
        this.props.query_obj.data_source.type === Constants.DATA_SOURCE_TYPES.hubspot
      ) {
        body = (
          <CRMForm
            apiInfo={this.props.apiInfo}
            queryObj={queryObj}
            inputsInQueryString={this.props.inputsInQueryString}
            updateTestResult={this.props.updateTestResult}
            refreshReport={this.refreshReport}
            entityType="input"
            input={this.props.input}
            inputMapping={this.props.inputMapping}
            onInputMappingUpdate={this.props.onInputMappingUpdate}
            dataSource={this.props.query_obj.data_source}
            fetchApiInfoIfNeeded={this.props.fetchApiInfoIfNeeded}
            formRef={this.props.formRef}
            isInputPopoverDisabled
            reportCount={this.props.reportCount}
            objectCount={this.props.objectCount}
            updateInputFilterDuplicates={this.updateFilterDuplicate}
          />
        );
      } else if (this.props.apiInfo && Object.keys(this.props.apiInfo).length > 0) {
        if (this.props.query_obj.data_source.type === 'looker') {
          body = (
            <LookerForm
              fetchApiInfoIfNeeded={this.props.fetchApiInfoIfNeeded}
              query={queryString}
              inputsInQueryString={this.props.inputsInQueryString}
              testIsLoading={this.props.testIsLoading}
              testQuery={this.props.testQuery}
              dataSourceId={this.props.query_obj.data_source.id}
              testResult={this.props.testResult}
              apiInfo={this.props.apiInfo}
              apiErrors={this.props.apiErrors}
              entityType="input"
              input={this.props.input}
              inputMapping={this.props.inputMapping}
              onInputMappingUpdate={this.props.onInputMappingUpdate}
              updateTestResult={this.props.updateTestResult}
              formRef={this.props.formRef}
              queryObj={queryObj}
            />
          );
        } else if (this.props.query_obj.data_source.type === Constants.DATA_SOURCE_TYPES.tableau) {
          body = (
            <TableauForm
              fetchApiInfoIfNeeded={this.props.fetchApiInfoIfNeeded}
              queryObj={queryObj}
              inputsInQueryString={this.props.inputsInQueryString}
              testIsLoading={this.props.testIsLoading}
              testQuery={this.props.testQuery}
              dataSourceId={this.props.query_obj.data_source.id}
              testResult={this.props.testResult}
              apiInfo={this.props.apiInfo}
              entityType="input"
              input={this.props.input}
              inputMapping={this.props.inputMapping}
              onInputMappingUpdate={this.props.onInputMappingUpdate}
            />
          );
        } else if (this.props.dataSource.type === Constants.DATA_SOURCE_TYPES.gainsight) {
          body = (
            <GainsightForm
              queryObj={queryObj}
              apiInfo={this.props.apiInfo}
              fetchApiInfoIfNeeded={this.props.fetchApiInfoIfNeeded}
              dataSourceId={this.props.dataSource.id}
              inputMapping={this.props.inputMapping}
              onInputMappingUpdate={this.props.onInputMappingUpdate}
              input={this.props.input}
              formRef={this.props.formRef}
              isInputPopoverDisabled
            />
          );
        }
      } else if (this.props.dataSource?.type === Constants.DATA_SOURCE_TYPES.productboard) {
        body = (
          <ProductboardForm
            queryObj={queryObj}
            dataSourceId={this.props.dataSource.id}
            isReadOnly={false}
            inputsInQueryString={this.props.inputsInQueryString}
            isInputPopoverDisabled
          />
        );
      } else if (this.props.query_obj.data_source.type === Constants.DATA_SOURCE_TYPES.api) {
        body = (
          <RestForm
            fetchApiInfoIfNeeded={this.props.fetchApiInfoIfNeeded}
            queryObj={queryObj}
            inputsInQueryString={this.props.inputsInQueryString}
            dataSource={this.props.dataSource}
            entityType="input"
            input={this.props.input}
            inputMapping={this.props.inputMapping}
            onInputMappingUpdate={this.props.onInputMappingUpdate}
          />
        );
      } else if (
        this.props.query_obj.data_source.type === Constants.DATA_SOURCE_TYPES.google_sheet ||
        this.props.query_obj.data_source.type === Constants.DATA_SOURCE_TYPES.excel
      ) {
        body = (
          <SpreadSheetCalculatorForm
            queryObj={queryObj}
            inputs={this.props.existingInputs}
            updateQueryObj={this.updateQueryObj}
            dataSource={this.props.dataSource}
            entityType="input"
            input={this.props.input}
            inputMapping={this.props.inputMapping}
            onInputMappingUpdate={this.props.onInputMappingUpdate}
            testResult={this.props.testResult}
            isInputPopoverDisabled
          />
        );
      } else if (this.props.query_obj.data_source.type === Constants.DATA_SOURCE_TYPES.power_bi) {
        body = (
          <PowerBIForm
            queryObj={queryObj}
            dataSourceId={this.props.dataSource.id}
            entityType="input"
            input={this.props.input}
            inputMapping={this.props.inputMapping}
            onInputMappingUpdate={this.props.onInputMappingUpdate}
            testResult={this.props.testResult}
          />
        );
      }
    }
    return (
      <React.Fragment>
        <Heading size={5}>API</Heading>
        <Form.Field className="mbl">
          <Form.Label>Data Source</Form.Label>
          <Form.Control>
            <Select
              classNamePrefix="matik-select"
              value={{ label: this.props.query_obj.data_source.name, value: this.props.query_obj.data_source.id }}
              onChange={this.props.onDataSourceUpdate}
              options={this.props.apiDataSources.map((dataSource) => ({
                value: dataSource.id,
                label: dataSource.name,
              }))}
              menuPortalTarget={this.props.formRef}
            />
          </Form.Control>
        </Form.Field>
        {body && <div>{body}</div>}
        <Form.Field className="is-flex end-align">
          <Button
            id="test-input-api"
            category="secondary"
            onClick={(e) => this.testQuery(e, queryString, queryObj)}
            status={this.props.testIsLoading ? 'loading' : 'default'}
          >
            Test API
          </Button>
        </Form.Field>
        {this.props.testResult && (
          <Form.Field>
            <div className="mbs">
              <TestResult
                testResult={this.props.testResult}
                noPadding={true}
                dataSource={this.props.dataSource?.type}
              />
            </div>
            <SampleData testResult={this.props.testResult} width={this.props.width} />
          </Form.Field>
        )}
      </React.Fragment>
    );
  }

  testQuery(e, queryString, queryObj) {
    e.preventDefault();
    this.props.testQuery(e, this.queryStringGenerator(queryString, queryObj));
  }

  queryStringGenerator = (queryString, queryObj) => {
    if (queryObj.orderByField && !queryObj.orderByDirection) {
      queryObj.orderByDirection = 'ASC';
    }
    if (
      queryObj.source &&
      queryObj.source !== 'reports' &&
      this.props.query_obj.data_source.type === Constants.DATA_SOURCE_TYPES.salesforce
    ) {
      queryObj['soql_string'] = dataSources.generateSOQLQueryString(queryString);
      return JSON.stringify(queryObj);
    } else if (this.state.filterDuplicates) {
      queryObj.filterDuplicates = this.state.filterDuplicates;
      return JSON.stringify(queryObj);
    } else {
      return JSON.stringify(queryObj);
    }
  };

  updateFilterDuplicate = (queryObj) => {
    queryObj.filterDuplicates = !this.state.filterDuplicates;
    this.updateQueryObj(queryObj);
    this.setState({ filterDuplicates: !this.state.filterDuplicates });
  };

  updateQueryObj = (queryObj) => {
    this.props.onQueryStringUpdate(JSON.stringify(queryObj));
  };

  refreshReport = (reportId, reportName) => {
    this.props.refreshSalesforceReportMetadata(this.props.dataSource.id, reportId, reportName);
  };
}

InputFormApi.propTypes = {
  apiInfo: PropTypes.object,
  apiErrors: PropTypes.object,
  apiDataSources: PropTypes.array,
  dataSource: PropTypes.object,
  existingInputs: PropTypes.object,
  fetchApiInfoIfNeeded: PropTypes.func,
  isFetching: PropTypes.bool,
  inputsInQueryString: PropTypes.object,
  onDataSourceUpdate: PropTypes.func,
  onQueryStringUpdate: PropTypes.func,
  query_obj: PropTypes.object,
  testResult: PropTypes.object,
  testQuery: PropTypes.func,
  testIsLoading: PropTypes.bool,
  updateTestResult: PropTypes.func,
  width: PropTypes.number,
  refreshSalesforceReportMetadata: PropTypes.func,
  input: PropTypes.object,
  inputMapping: PropTypes.object,
  onInputMappingUpdate: PropTypes.func,
  formRef: PropTypes.object,
  fetchReportCountFromApi: PropTypes.func,
  fetchObjectCountFromApi: PropTypes.func,
  reportCount: PropTypes.number,
  objectCount: PropTypes.number,
};

export default WithTestQuery(connect(mapApiInfoStateToProps, mapDispatchToProps)(InputFormApi));
