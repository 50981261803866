import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import emptyStateImg from '../../images/SlideNotFound.png';
import Constants from 'components/Constants';
import API from '../../lib/api';
import DisconnectedTemplateSlide from './DisconnectedTemplateSlide';

function AsyncLoadImage(props) {
  const [imageInfo, setImageInfo] = useState(null);

  useEffect(() => {
    if (
      props.fetchUrl &&
      props.fetchState !== 'fetching' &&
      props.fetchState !== 'error' &&
      props.fetchState !== 'fetched'
    ) {
      fetchImage();
    } else if (
      props.template &&
      props.template.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL &&
      props.fetchState !== 'fetching' &&
      props.fetchState !== 'error' &&
      props.fetchState !== 'fetched'
    ) {
      props.onFetchStateChange?.('fetched');
    }
  }, [props.template, props.fetchUrl]);

  const fetchImage = () => {
    if (!props.isBulk) {
      API.get(
        props.fetchUrl,
        (response) => {
          props.onFetchStateChange?.('fetched');
          setImageInfo(response.data);
        },
        () => {
          props.onFetchStateChange?.('error');
        },
      );
    }
  };

  const skeleton = (
    <Skeleton
      containerClassName="image-wrapper skeleton-image-wrapper"
      height={props.height}
      borderRadius="4px"
      duration={1.2}
      baseColor="#EFF1F6"
    />
  );
  let srcHtml = props.template?.slides?.[0]?.slide_xml || props.template?.first_slide?.slide_xml;
  const iFrame = (
    <iframe sandbox="allow-same-origin" className={props.imageClass + ' template-card-iframe'} srcDoc={srcHtml} />
  );
  const img = (
    <img
      className={props.imageClass}
      src={props.fetchStatus?.data?.url || imageInfo?.url || emptyStateImg}
      alt={props.imgAlt || 'Slide'}
      height={props.height}
      style={{ height: props.height }}
    />
  );

  let body = '';

  if (props.usesIframe) {
    body = iFrame;
  } else if (props.status && props.status?.status === 'error') {
    body = (
      <DisconnectedTemplateSlide height={parseInt(props?.height || 0)} showingSingleSlide={props.showingSingleSlide} />
    );
  } else if (!props.fetchUrl) {
    if (props.fallbackImg) {
      body = img;
    } else {
      body = skeleton;
    }
  } else {
    if ((props.isBulk && props.fetchStatus) || props.fetchUrl) {
      if (
        !imageInfo &&
        (!props.fetchStatus?.fetchState ||
          props.fetchStatus?.fetchState === 'loading' ||
          props.fetchStatus?.fetchState === 'fetching')
      ) {
        body = skeleton;
      } else if (props.fetchStatus?.fetchState === 'fetched' && props.fetchStatus?.data) {
        body = props.fetchStatus?.data?.url ? img : 'Image not found';
      } else {
        body = img;
      }
    }
  }

  return (
    <div
      style={{
        width: props.width || 'auto',
        minHeight: props.height,
        maxHeight: `${parseInt(props.height, 10) + 3}px`,
      }}
      className={`${props.imageWrapperClass ? props.imageWrapperClass : ''} ${
        props.usesIframe ? 'iframe-wrap' : 'image-wrapper'
      }`}
    >
      {body}
    </div>
  );
}

AsyncLoadImage.propTypes = {
  fetchUrl: PropTypes.string,
  fallbackImg: PropTypes.string,
  imageClass: PropTypes.string,
  imageWrapperClass: PropTypes.string,
  isBulk: PropTypes.bool,
  fetchStatus: PropTypes.object,
  height: PropTypes.string,
  width: PropTypes.string,
  imgAlt: PropTypes.string,
  template: PropTypes.object,
  usesIframe: PropTypes.bool,
  fetchState: PropTypes.string,
  onFetchStateChange: PropTypes.func,
  status: PropTypes.object,
  showingSingleSlide: PropTypes.bool,
};

AsyncLoadImage.defaultProps = {
  imageClass: 'async-rounded-top',
};

export default AsyncLoadImage;
