import React from 'react';
import PropTypes from 'prop-types';
import SlidesPresentationCreator from './SlidesPresentationCreator';
import EmailPresentationCreator from './EmailPresentationCreator';
import DocumentPresentationCreator from './DocumentPresentationCreator';
import SpreadsheetPresentationCreator from 'components/producer/templates/SpreadsheetPresentationCreator';
import { withRouter } from 'react-router';
import Constants from '../Constants';
import useTemplate, { useTemplateMutator } from 'lib/hooks/useTemplate';
import useAccesses from 'lib/hooks/useAccess';
import { useRouteMatch } from 'react-router-dom';

function PresentationCreator(props) {
  const match = useRouteMatch();
  const templateId = match?.params?.template_id || match?.params?.id;
  const { data: template, isPending: isLoading } = useTemplate(templateId);
  const { data: attachedTemplate, isPending: isAttachmentLoading } = useTemplate(template?.attached_template_id);
  const { update } = useTemplateMutator();
  const { data: accessesByTemplateId } = useAccesses('template');

  return (
    <div className={props.hideHeader ? 'template-wrapper' : 'wrapper'}>
      <div className={props.hideHeader ? 'main phn mt-0' : 'main phn'}>
        {template?.source_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL ? (
          <EmailPresentationCreator
            template={template}
            attachedTemplate={attachedTemplate}
            onTemplateUpdateWithServerCall={update}
            userDataSources={props.userDataSources}
            onUserDataSourceAdd={props.onUserDataSourceAdd}
            elements={template ? [template] : null}
            isLoading={isLoading || (template?.attached_template_id && isAttachmentLoading)}
            emptyInfo={props.emptyInfo}
            accessesByTemplateId={accessesByTemplateId}
            onPresentationCreate={props.onPresentationCreate}
          />
        ) : Constants.TEMPLATE_SOURCE_TYPES.DOCUMENT_TYPES.includes(template?.source_type) ? (
          <DocumentPresentationCreator
            hideHeader={props.hideHeader}
            template={template}
            accessesByTemplateId={accessesByTemplateId}
            elements={template ? [template] : null}
            isLoading={isLoading}
            emptyInfo={props.emptyInfo}
            onPresentationCreate={props.onPresentationCreate}
            onTemplateUpdateWithServerCall={update}
            onUserDataSourceAdd={props.onUserDataSourceAdd}
            userDataSources={props.userDataSources}
          />
        ) : Constants.TEMPLATE_SOURCE_TYPES.SPREADSHEET_TYPES.includes(template?.source_type) ? (
          <SpreadsheetPresentationCreator
            hideHeader={props.hideHeader}
            template={template}
            accessesByTemplateId={accessesByTemplateId}
            elements={template ? [template] : null}
            isLoading={isLoading}
            emptyInfo={props.emptyInfo}
            onPresentationCreate={props.onPresentationCreate}
            onTemplateUpdateWithServerCall={update}
            onUserDataSourceAdd={props.onUserDataSourceAdd}
            userDataSources={props.userDataSources}
          />
        ) : (
          <SlidesPresentationCreator
            // Reset state when template changes
            key={template?.id}
            hideHeader={props.hideHeader}
            template={template}
            accessesByTemplateId={accessesByTemplateId}
            elements={template ? [template] : null}
            isLoading={isLoading}
            emptyInfo={props.emptyInfo}
            onPresentationCreate={props.onPresentationCreate}
            onTemplateUpdateWithServerCall={update}
            onUserDataSourceAdd={props.onUserDataSourceAdd}
            userDataSources={props.userDataSources}
          />
        )}
      </div>
    </div>
  );
}

PresentationCreator.propTypes = {
  //State managed by parent
  presentations: PropTypes.array,
  schedule: PropTypes.object,
  userDataSources: PropTypes.object,
  onTemplateUpdateWithServerCall: PropTypes.func,
  emptyInfo: PropTypes.object,
  hideHeader: PropTypes.bool,
  //Callbacks from parent
  onPresentationCreate: PropTypes.func,
  resetPresentationFlow: PropTypes.func,
  fetchPresentation: PropTypes.func,
  onUserDataSourceAdd: PropTypes.func,
  //From withRouter
  history: PropTypes.object,
  match: PropTypes.any,
  location: PropTypes.object,
};

export default withRouter(PresentationCreator);
