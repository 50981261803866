import { useQueries } from '@tanstack/react-query';
import API from '../api';
import LongRequest from '../longRequest';

export const useTemplateTagMutator = () => {
  const getStatusCallback = (response, onComplete, onDone) => {
    if (response.data.template_status === 'done' || response.data.template_status === 'unknown') {
      onComplete();
      onDone(response.data);
    }
  };
  const create = (tagBody, tagId, currentTemplate, selectedSlide, textSelection) => {
    return new Promise((resolve, reject) => {
      const data = {
        content_tag: tagBody,
        page_element_id: tagId,
        text_to_replace: textSelection,
      };
      const longRequest = new LongRequest('/templates');
      API.post(
        `/templates/${currentTemplate.id}/${selectedSlide.slide_id}/tag/`,
        data,
        (initialResponse) =>
          longRequest.getStatus(
            currentTemplate.id,
            (response, onComplete) => {
              if (initialResponse.data?.tag) {
                response.data.tag = initialResponse.data.tag;
              }
              getStatusCallback(response, onComplete, resolve);
            },
            (err) => reject(err),
          ),
        (err) => reject(err),
      );

      API.track('create_tag', {
        templateType: currentTemplate.source_type, // word_365, google_slides, etc...
      });
    });
  };

  const update = (tagBody, tagId, existingTag, currentTemplate, selectedSlide) => {
    return new Promise((resolve, reject) => {
      const data = {
        new_content_tag: tagBody,
        content_tag_to_replace: existingTag,
        page_element_id: tagId,
      };
      const longRequest = new LongRequest('/templates');
      API.put(
        `/templates/${currentTemplate.id}/${selectedSlide.slide_id}/tag/`,
        data,
        () =>
          longRequest.getStatus(
            currentTemplate.id,
            (response, onComplete) => getStatusCallback(response, onComplete, () => resolve(tagBody, tagId)),
            (err) => reject(err),
          ),
        (err) => reject(err),
      );

      API.track('save_tag', {
        templateType: currentTemplate.source_type, // word_365, google_slides, etc...
      });
    });
  };

  const deleteTag = (elementId, tagName, currentTemplate, selectedSlide) => {
    return new Promise((resolve, reject) => {
      const longRequest = new LongRequest('/templates');

      API.delete(
        `/templates/${currentTemplate.id}/${selectedSlide.slide_id}/tag/${elementId}/${tagName}/`,
        () =>
          longRequest.getStatus(
            currentTemplate.id,
            (response, onComplete) => getStatusCallback(response, onComplete, () => resolve()),
            (err) => reject(err),
          ),
        (err) => reject(err),
      );

      API.track('delete_tag', {
        templateType: currentTemplate.source_type, // word_365, google_slides, etc...
      });
    });
  };

  return {
    create,
    deleteTag,
    update,
  };
};

/** Given an array of TagTree Nodes, lookup any that are unpopulated to determine if they point to
 * non-existent data or existent but inaccessible data. For inaccessible data, populate the tag's
 * `matchingContent` with `{ id, name }`.
 * @returns true if the data is fetching, false if not.
 */
export function useInaccessibleContent(tags) {
  const missingTags = (tags || [])
    .filter((tag) => !tag.matchingContent)
    // The content referred to by the tag either doesn't exist or is not readable by the user
    .map((tag) => tag.name);

  const queries = missingTags.map((tag) => ({
    queryKey: ['inaccessible_content', tag],
    queryFn: () =>
      API.get(
        `/dynamic_content/name/${tag}/`,
        (response) => response.data,
        (err) => {
          throw err.response;
        },
      ),
    retryOnMount: false,
  }));

  const results = useQueries({ queries });
  for (let i = 0; i < missingTags.length; i++) {
    const result = results[i];
    if (!result.isLoading) {
      const tag = tags.find((t) => t.name === missingTags[i]);
      if (result.data) {
        // Unexpected but we might as well populate the data if we find it here.
        tag.matchingContent = result.data;
      } else if (result.error) {
        // Expected!
        if (result.error.status === 403) {
          // The content exists, but we aren't allowed to see it.
          tag.matchingContent = {
            name: tag.name,
            id: result.error.data.dynamic_content_id,
          };
        }
      }
    }
  }

  // return isLoading
  return results.some((result) => result.isLoading);
}
