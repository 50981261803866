import * as pbi from 'powerbi-client';
import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bulma-components';

import Constants from 'components/Constants';
import { DynamicContentContext } from 'components/producer/dynamicContent/DynamicContentContext';
import InputMapping from 'components/producer/dynamicContent/InputMapping';
import QueryEditor from 'components/producer/dynamicContent/QueryEditor';
import { MAlert } from 'components/shared/Alerts';
import { Select } from 'components/shared/FormSelect';
import API from 'lib/api';

/**
 * A form that allows an admin to select a form type for a Power BI data source.
 * The form type determines the form that is rendered.
 */
const PowerBIForm = (props) => {
  const { queryObj, entityType, input, inputMapping, onInputMappingUpdate, testResult, validationErrors } = props;

  const [formType, setFormType] = useState(queryObj?.type ?? null);
  const dynamicContentContext = useContext(DynamicContentContext);
  const dynamicContentType = dynamicContentContext.dynamicContentType;
  const isImage = dynamicContentType === Constants.DynamicContentTypes.IMAGE;
  const isInput = entityType === 'input';

  const formTypeSelectOptions = [
    { label: 'Report', value: 'report' },
    { label: 'Semantic Model (Dataset)', value: 'semantic_model' },
  ];

  const selectedFormTypeOption = formTypeSelectOptions.find((option) => {
    return option.value === formType;
  });

  const selectFormType = (formType) => {
    let updatedQueryObj = null;

    switch (formType) {
      case 'semantic_model': {
        updatedQueryObj = Object.assign({}, queryObj, {
          version: 'v1',
          type: 'semantic_model',
          workspace_id: null,
          semantic_model_id: null,
          dax_query: null,
        });
        break;
      }

      case 'report': {
        updatedQueryObj = Object.assign({}, queryObj, {
          version: 'v1',
          type: 'report',
          workspace_id: null,
          report_id: null,
        });
        break;
      }

      default: {
        MAlert('Invalid form type.', 'Error', 'error');
      }
    }

    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
    setFormType(formType);
  };

  const selectFormTypeOption = (option, event) => {
    if (event.action === 'select-option') {
      selectFormType(option.value);
    }
  };

  useEffect(() => {
    if (formType === null) {
      // Initialize the form model.
      selectFormType('semantic_model');
    }
  }, []);

  useEffect(() => {
    // The "Report" form type is only supported for image dynamic content.
    // If the dynamic content type changes, then the form type should change
    // to the "Semantic Model" form type.
    if (dynamicContentType !== Constants.DynamicContentTypes.IMAGE && formType === 'report') {
      selectFormType('semantic_model');
    }
  }, [dynamicContentType]);

  return (
    <React.Fragment>
      <Form.Field className={isInput ? 'mb-5 hidden' : 'mb-5'}>
        <Form.Label>Form Type</Form.Label>
        <Form.Help>Select a form type</Form.Help>
        <Form.Control>
          <Select
            name="form_type"
            classNamePrefix="matik-select"
            aria-label="Select a form type"
            placeholder="Select a form type"
            value={selectedFormTypeOption}
            onChange={selectFormTypeOption}
            options={formTypeSelectOptions}
            isDisabled={isInput || !isImage}
          />
        </Form.Control>
        <Form.Help color="danger">{validationErrors?.form_type}</Form.Help>
      </Form.Field>

      {formType === 'report' && <PowerBIReportForm {...props} />}
      {formType === 'semantic_model' && <PowerBISemanticModelForm {...props} />}

      {isInput && (
        <PowerBIInputMappingForm
          input={input}
          inputMapping={inputMapping}
          onInputMappingUpdate={onInputMappingUpdate}
          testResult={testResult}
        />
      )}
    </React.Fragment>
  );
};

PowerBIForm.propTypes = {
  queryObj: PropTypes.object,
  dataSourceId: PropTypes.string,
  entityType: PropTypes.string,
  input: PropTypes.object,
  inputMapping: PropTypes.object,
  onInputMappingUpdate: PropTypes.func,
  testResult: PropTypes.object,
  validationErrors: PropTypes.object,
};

/**
 * A form that allows an admin to define an input mapping for inputs that use
 * Power BI as an API source. Input Mapping allows selected inputs to be mapped
 * to equivalent values which can be used as dynamic content inputs.
 */
const PowerBIInputMappingForm = (props) => {
  const { input, inputMapping, onInputMappingUpdate, testResult } = props;

  // Parse fields from the result set for input mapping.
  const resultSet = (testResult ?? {})?.result ?? [];
  const resultHeaders = resultSet.length > 0 ? resultSet[0] : [];

  const inputMappingReturnFields = resultHeaders.map((header) => ({
    label: header,
    value: header,
    displayName: header,
  }));

  return (
    <InputMapping
      input={input}
      inputSourceType="query"
      inputMapping={inputMapping}
      onInputMappingUpdate={onInputMappingUpdate}
      returnFieldsArray={inputMappingReturnFields}
    />
  );
};

PowerBIInputMappingForm.propTypes = {
  input: PropTypes.object,
  inputMapping: PropTypes.object,
  onInputMappingUpdate: PropTypes.func,
  testResult: PropTypes.object,
};

/**
 * A form that allows an admin to select a semantic model (previously known as
 * dataset) in their Power BI workspace and define a DAX query that will be run
 * when the dynamic content is evaluated.
 */
const PowerBISemanticModelForm = (props) => {
  const { queryObj, dataSourceId, validationErrors } = props;

  const [workspaceId, setWorkspaceId] = useState(queryObj?.workspace_id ?? null);
  const [semanticModelId, setSemanticModelId] = useState(queryObj?.semantic_model_id ?? null);
  const dynamicContentContext = useContext(DynamicContentContext);
  const workspaces = usePowerBIWorkspaces(dataSourceId);
  const semanticModels = usePowerBISemanticModels(dataSourceId, workspaceId);

  const workspaceSelectOptions = workspaces.data.map((workspace) => ({
    label: workspace.name,
    value: workspace.id,
  }));

  const semanticModelSelectOptions = semanticModels.data.map((semanticModel) => ({
    label: semanticModel.name,
    value: semanticModel.id,
  }));

  const selectedWorkspaceOption = workspaceSelectOptions.find((option) => {
    return option.value === workspaceId;
  });

  const selectedSemanticModelOption = semanticModelSelectOptions.find((option) => {
    return option.value === semanticModelId;
  });

  const selectWorkspaceOption = (option, event) => {
    if (event.action === 'select-option') {
      const updatedQueryObj = Object.assign({}, queryObj, {
        workspace_id: option.value,
        semantic_model_id: null,
      });

      dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
      setWorkspaceId(option.value);
    }
  };

  const selectSemanticModelOption = (option, event) => {
    if (event.action === 'select-option') {
      const updatedQueryObj = Object.assign({}, queryObj, {
        semantic_model_id: option.value,
      });

      dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
      setSemanticModelId(option.value);
    }
  };

  const updateDAXQuery = (query) => {
    const updatedQueryObj = Object.assign({}, queryObj, { dax_query: query });
    dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
  };

  return (
    <React.Fragment>
      <Form.Field className="mb-5">
        <Form.Label>Workspace</Form.Label>
        <Form.Help>Select a workspace</Form.Help>
        <Form.Control>
          <Select
            name="workspace"
            classNamePrefix="matik-select"
            aria-label="Select a workspace"
            placeholder="Select a workspace"
            isDisabled={workspaces.isLoading}
            value={selectedWorkspaceOption}
            onChange={selectWorkspaceOption}
            options={workspaceSelectOptions}
          />
        </Form.Control>
        <Form.Help color="danger">{validationErrors?.workspace_id}</Form.Help>
      </Form.Field>
      <Form.Field className="mb-5">
        <Form.Label>Semantic Model</Form.Label>
        <Form.Help>Select a semantic model (dataset)</Form.Help>
        <Form.Control>
          <Select
            name="semantic_model"
            classNamePrefix="matik-select"
            aria-label="Select a semantic model"
            placeholder="Select a semantic model"
            isDisabled={!workspaceId || semanticModels.isLoading}
            value={selectedSemanticModelOption}
            onChange={selectSemanticModelOption}
            options={semanticModelSelectOptions}
          />
        </Form.Control>
        <Form.Help color="danger">{validationErrors?.semantic_model_id}</Form.Help>
      </Form.Field>
      <Form.Field className="mb-5">
        <Form.Label>DAX Query</Form.Label>
        <Form.Help>Enter a DAX query</Form.Help>
        <Form.Control>
          <QueryEditor
            mode="plaintext"
            isReadOnly={!semanticModelId}
            inputs={dynamicContentContext.existingInputs}
            queryString={queryObj?.dax_query ?? ''}
            onQueryStringUpdate={updateDAXQuery}
          />
        </Form.Control>
        <Form.Help color="danger">{validationErrors?.dax_query}</Form.Help>
      </Form.Field>
    </React.Fragment>
  );
};

PowerBISemanticModelForm.propTypes = {
  queryObj: PropTypes.object,
  dataSourceId: PropTypes.string,
  validationErrors: PropTypes.object,
};

/**
 * A form that allows an admin to select a report in their Power BI workspace.
 * The selected report is embedded within the application so that the admin can
 * configure a visualization to use as image dynamic content.
 */
const PowerBIReportForm = (props) => {
  const { queryObj, dataSourceId } = props;

  const [workspaceId, setWorkspaceId] = useState(null);
  const [reportEmbedURL, setReportEmbedURL] = useState(null);
  const [reportId, setReportId] = useState(null);
  const [semanticModelId, setSemanticModelId] = useState(null);
  const dynamicContentContext = useContext(DynamicContentContext);
  const workspaces = usePowerBIWorkspaces(dataSourceId);
  const reports = usePowerBIReports(dataSourceId, workspaceId);
  const reportEmbedToken = usePowerBIEmbedToken(dataSourceId, reportId, semanticModelId);

  const workspaceSelectOptions = workspaces.data.map((workspace) => ({
    label: workspace.name,
    value: workspace.id,
  }));

  const reportSelectOptions = reports.data.map((report) => ({
    label: report.name,
    value: {
      reportId: report.id,
      reportEmbedURL: report.embedUrl,
      semanticModelId: report.datasetId,
    },
  }));

  const selectedWorkspaceOption = workspaceSelectOptions.find((option) => {
    return option.value === workspaceId;
  });

  const selectedReportOption = reportSelectOptions.find((option) => {
    return option.value.reportId === reportId;
  });

  const selectWorkspaceOption = (option, event) => {
    if (event.action === 'select-option') {
      const updatedQueryObj = Object.assign({}, queryObj, { workspaceOption: option });
      dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
      setWorkspaceId(option.value);
    }
  };

  const selectReportOption = (option, event) => {
    if (event.action === 'select-option') {
      const updatedQueryObj = Object.assign({}, queryObj, { reportOption: option });
      dynamicContentContext.onQueryObjectUpdate(updatedQueryObj);
      setReportId(option.value.reportId);
      setReportEmbedURL(option.value.reportEmbedURL);
      setSemanticModelId(option.value.semanticModelId);
    }
  };

  return (
    <React.Fragment>
      <Form.Field className="mb-5">
        <Form.Label>Workspace</Form.Label>
        <Form.Help>Select a workspace</Form.Help>
        <Form.Control>
          <Select
            name="workspace"
            classNamePrefix="matik-select"
            aria-label="Select a workspace"
            placeholder="Select a workspace"
            isDisabled={workspaces.isLoading}
            value={selectedWorkspaceOption}
            onChange={selectWorkspaceOption}
            options={workspaceSelectOptions}
          />
        </Form.Control>
      </Form.Field>
      <Form.Field className="mb-5">
        <Form.Label>Report</Form.Label>
        <Form.Help>Select a report</Form.Help>
        <Form.Control>
          <Select
            name="report"
            classNamePrefix="matik-select"
            aria-label="Select a report"
            placeholder="Select a report"
            isDisabled={!workspaceId || reports.isLoading}
            value={selectedReportOption}
            onChange={selectReportOption}
            options={reportSelectOptions}
          />
        </Form.Control>
      </Form.Field>

      {reportEmbedToken.data !== null && reportEmbedURL !== null && (
        <PowerBIEmbed embedToken={reportEmbedToken.data} embedURL={reportEmbedURL} />
      )}
    </React.Fragment>
  );
};

PowerBIReportForm.propTypes = {
  queryObj: PropTypes.object,
  dataSourceId: PropTypes.string,
};

/**
 * A component that embeds a Power BI report into the application.
 */
const PowerBIEmbed = (props) => {
  const { embedToken, embedURL } = props;

  const embeddedContainerRef = useRef(null);

  const embedConfiguration = {
    type: 'report',
    tokenType: pbi.models.TokenType.Embed,
    accessToken: embedToken,
    embedUrl: embedURL,
    permissions: pbi.models.Permissions.Read,
    settings: {
      navContentPaneEnabled: false,
      filterPaneEnabled: false,
      layoutType: pbi.models.LayoutType.Custom,
      customLayout: {
        displayOption: pbi.models.DisplayOption.FitToPage,
      },
    },
  };

  useEffect(() => {
    const powerBI = new pbi.service.Service(
      pbi.factories.hpmFactory,
      pbi.factories.wpmpFactory,
      pbi.factories.routerFactory,
    );

    powerBI.embed(embeddedContainerRef.current, embedConfiguration);
  }, []);

  return (
    <React.Fragment>
      <div ref={embeddedContainerRef} className="w-full" style={{ height: '800px' }} />
    </React.Fragment>
  );
};

PowerBIEmbed.propTypes = {
  embedToken: PropTypes.string,
  embedURL: PropTypes.string,
};

/**
 * Queries the access token from a Power BI data source.
 *
 * @property {string} dataSourceId - Data source ID.
 * @property {string} reportId - Report ID.
 * @property {string} semanticModelId - Semantic model ID.
 */
const usePowerBIEmbedToken = (dataSourceId, reportId, semanticModelId) => {
  const { data, error, isLoading, isError } = useQuery({
    queryKey: ['power_bi', dataSourceId, 'embed_token', reportId, semanticModelId],
    queryFn: () => {
      return API.post(
        `/data_sources/${dataSourceId}/power_bi/embed_token`,
        {
          report_id: reportId,
          semantic_model_id: semanticModelId,
        },
        (response) => {
          const embed_token = response?.data?.embed_token?.token ?? '';
          return embed_token;
        },
        () => {
          MAlert('Failed to get Power BI embed token', 'Error', 'error');
        },
      );
    },
    placeholderData: null,
    enabled: reportId !== null && semanticModelId !== null,
  });

  return {
    data,
    error,
    isLoading,
    isError,
  };
};

/**
 * Queries the workspaces from a Power BI data source.
 *
 * @property {string} dataSourceId - Data source ID.
 */
const usePowerBIWorkspaces = (dataSourceId) => {
  const { data, error, isLoading, isError } = useQuery({
    queryKey: ['power_bi', dataSourceId, 'workspaces'],
    queryFn: () => {
      return API.get(
        `/data_sources/${dataSourceId}/power_bi/workspaces`,
        (response) => {
          const workspaces = response?.data?.workspaces?.value ?? [];
          return workspaces;
        },
        () => {
          MAlert('Failed to get Power BI workspaces', 'Error', 'error');
        },
      );
    },
    placeholderData: [],
  });

  return {
    data,
    error,
    isLoading,
    isError,
  };
};

/**
 * Queries the semantic models in a workspace from a Power BI data source.
 *
 * @property {string} dataSourceId - Data source ID.
 * @property {string} workspaceId - Workspace ID.
 */
const usePowerBISemanticModels = (dataSourceId, workspaceId) => {
  const { data, error, isLoading, isError } = useQuery({
    queryKey: ['power_bi', dataSourceId, 'workspaces', workspaceId, 'semantic_models'],
    queryFn: () => {
      return API.get(
        `/data_sources/${dataSourceId}/power_bi/workspaces/${workspaceId}/semantic_models`,
        (response) => {
          const semanticModels = response?.data?.semantic_models?.value ?? [];
          return semanticModels;
        },
        () => {
          MAlert('Failed to get Power BI semantic models', 'Error', 'error');
        },
      );
    },
    placeholderData: [],
    enabled: workspaceId !== null,
  });

  return {
    data,
    error,
    isLoading,
    isError,
  };
};

/**
 * Queries the reports in a workspace from a Power BI data source.
 *
 * @property {string} dataSourceId - Data source ID.
 * @property {string} workspaceId - Workspace ID.
 */
const usePowerBIReports = (dataSourceId, workspaceId) => {
  const { data, error, isLoading, isError } = useQuery({
    queryKey: ['power_bi', dataSourceId, 'workspaces', workspaceId, 'reports'],
    queryFn: () => {
      return API.get(
        `/data_sources/${dataSourceId}/power_bi/workspaces/${workspaceId}/reports`,
        (response) => {
          const reports = response?.data?.workspaces?.value ?? [];
          return reports;
        },
        () => {
          MAlert('Failed to get Power BI reports', 'Error', 'error');
        },
      );
    },
    placeholderData: [],
    enabled: workspaceId !== null,
  });

  return {
    data,
    error,
    isLoading,
    isError,
  };
};

/**
 * Validates that a Power BI semantic model query is valid.
 *
 * @property {object} query - Semantic model query.
 * @returns {object} A validation result.
 */
const validatePowerBISemanticModelQuery = (query) => {
  const errors = {};

  if (!query?.version) {
    errors['version'] = 'The Power BI query is missing a version number.';
  }

  if (query?.type !== 'semantic_model') {
    errors['type'] = 'The Power BI query has an invalid type.';
  }

  if (!query?.workspace_id) {
    errors['workspace_id'] = 'A workspace is required.';
  }

  if (!query?.semantic_model_id) {
    errors['semantic_model_id'] = 'A semantic model (dataset) is required.';
  }

  if (!query?.dax_query) {
    errors['dax_query'] = 'A DAX query is required.';
  }

  return {
    isValid: Object.values(errors).length === 0,
    errors,
  };
};

/**
 * Validates that a Power BI report query is valid.
 *
 * @property {object} query - Semantic model query.
 * @returns {object} A validation result.
 */
const validatePowerBIReportQuery = () => {
  // [TODO] Add support for report queries [MPD-6150].
  MAlert('Power BI reports are not supported yet.', 'Error', 'error');
  return { isValid: false, errors: {} };
};

export default PowerBIForm;

export {
  PowerBIForm,
  PowerBIInputMappingForm,
  PowerBISemanticModelForm,
  PowerBIReportForm,
  validatePowerBISemanticModelQuery,
  validatePowerBIReportQuery,
};
