import ApiReturnFields from './ApiReturnFields';
import PropTypes from 'prop-types';
import React from 'react';

function CRMReturnFields({
  allFields,
  returnFields,
  fieldGroups,
  objectFields,
  returnFieldMapping,
  onReturnFieldAdd,
  onReturnFieldRemove,
  onReturnFieldSelectAll,
  onReturnFieldClear,
  onReturnFieldMappingUpdate,
  dataSourceName,
  removeToolTip,
  noOptionsMessage,
}) {
  let inErrorState = false;
  const dataSourceNameMsg = dataSourceName || 'Data Source';
  const fieldOptions = fieldGroups.map((group) => {
    const groupMap = group.options.map((groupOptionValue) => {
      const selected = returnFields.includes(groupOptionValue.value);
      const fieldInErrorState =
        !allFields.includes(groupOptionValue.label) && returnFields.includes(groupOptionValue.value);

      if (fieldInErrorState) {
        inErrorState = true;
      }

      return {
        name: groupOptionValue.value,
        label: groupOptionValue.label,
        type: groupOptionValue.type,
        //crm forms require the options menu to show the label and the return fields to show the group relationship value
        returnFieldLabel: objectFields?.includes(groupOptionValue.value)
          ? groupOptionValue.label
          : groupOptionValue.value,
        selected: selected,
        inErrorState: fieldInErrorState,
        errorMessage: fieldInErrorState
          ? `This field can no longer be found in ${dataSourceNameMsg}. Remove it or replace it with a valid field`
          : '',
        hidden: false,
        hideable: false,
        hideToolTip: '',
        removable: true,
        removeToolTip: removeToolTip,
        iconName: null,
        iconToolTip: '',
        alias:
          returnFieldMapping[groupOptionValue.value]?.displayName || returnFieldMapping[groupOptionValue.value] || '',
      };
    });
    return { type: group.type, label: group.label, options: groupMap };
  });

  return (
    <ApiReturnFields
      returnFields={returnFields}
      inErrorState={inErrorState}
      errorMessage={inErrorState ? 'Contains fields that cannot be found in Data Source' : ''}
      fieldOptions={fieldOptions}
      canHide={false}
      onReturnFieldAdd={onReturnFieldAdd}
      onReturnFieldRemove={onReturnFieldRemove}
      onReturnFieldSelectAll={onReturnFieldSelectAll}
      onReturnFieldClear={onReturnFieldClear}
      onReturnFieldHide={null}
      onReturnFieldShow={null}
      onReturnFieldsMappingUpdate={onReturnFieldMappingUpdate}
      isReadOnly={false}
      noOptionsMessage={noOptionsMessage}
      canCreateReturnField={false}
    />
  );
}
CRMReturnFields.propTypes = {
  allFields: PropTypes.arrayOf(PropTypes.string),
  returnFields: PropTypes.array,
  fieldGroups: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      label: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string,
        }),
      ),
    }),
  ),
  objectFields: PropTypes.array,
  returnFieldMapping: PropTypes.object,
  onReturnFieldAdd: PropTypes.func,
  onReturnFieldRemove: PropTypes.func,
  onReturnFieldSelectAll: PropTypes.func,
  onReturnFieldClear: PropTypes.func,
  onReturnFieldMappingUpdate: PropTypes.func,
  dataSourceName: PropTypes.string,
  removeToolTip: PropTypes.string,
  noOptionsMessage: PropTypes.string,
};

export default CRMReturnFields;
